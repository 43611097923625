import React from 'react'

const UserNameImage = ({image,name,width,height,margin,title,fontSize}) => {
    const getInitials = (name) => {
        const words = name.split(' ');
        const initials = words.map(word => word[0]);
        return initials.join('');
      };
  return (
    <div style={{width,height,margin,fontSize}}title={title}>
      {image==""?<div className='user_image_box'>{getInitials(name)}</div>:
                // <img src={image==""?"/Images/dummy.jpg":image} />
                <img src={image==""?"/Images/dummy.jpg":process.env.REACT_APP_IMG_URL+image} />
               }
    </div>
  )
}

export default UserNameImage
