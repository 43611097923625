import React from 'react'

const DatesModal = ({ userdateData }) => {
    return (
        <div className='dates-box'>
            <h5>Start Dates</h5>
            <div className='dates-card mb-3'>

                <div className='d-flex justify-content-between border-bottom'>
                    <h6>Original</h6>
                    <h6><b>{userdateData.sample_process_start_time}</b></h6>
                </div>
                <div className='d-flex justify-content-between border-bottom'>
                    <h6>Current</h6>
                    <h6><b>{userdateData.sample_process_plan_start_time}</b></h6>
                </div>
                <div className='d-flex justify-content-between border-bottom'>
                    <h6>Actual</h6>
                    <h6><b>{userdateData.sample_process_actual_start_time}</b></h6>
                </div>

            </div>
            <h5>End Dates</h5>
            <div className='dates-card mb-3'>

                <div className='d-flex justify-content-between border-bottom'>
                    <h6>Original</h6>
                    <h6><b>{userdateData.sample_process_deadline}</b></h6>
                </div>
                <div className='d-flex justify-content-between border-bottom'>
                    <h6>Current</h6>
                    <h6><b>{userdateData.sample_process_plan_time_deadline}</b></h6>
                </div>
                <div className='d-flex justify-content-between border-bottom'>
                    <h6>Actual</h6>
                    <h6><b>{userdateData.sample_process_completion_timestamp}</b></h6>
                </div>



            </div>

        </div>
    )
}

export default DatesModal
