import React, { useState } from 'react'
import AllPendingTable from './components/AllPendingTable'
import DashboardSelect from '../Dashboard/component/DashboardSelect'
import TaskProfile from './components/TaskProfile'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { getUser } from '../../util/Common'

const AllPendingTask = () => {
  const [selectBox, setSelectBox] = useState(true);
  const filterValue = (selectValue) => {
    setSelectBox(selectValue)
  }
  const downloadExcel = async() => {
    let excel = process.env.REACT_APP_WEB_URL+"/panding-task?"
    // const user = await getUser() 
    // selectBox.userId=user.id
    for (const key in selectBox) {
      if (selectBox.hasOwnProperty(key)) {
        excel+=`${key}=${selectBox[key]}&`
      }
    }
    window.open(excel,"_blank")
  }
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>AllPendingTask | Sample Dashboard</title>
      </Helmet>
      <div className='d-flex align-items-baseline justify-content-between pb-4'>

        <div className="heading">
          <h2>All Pending Task</h2>
        </div>
        <div className='download-but'>
          <button onClick={()=>downloadExcel()}><img src="Images/document-download.svg" />Download Excel</button>
          <div className='view-but'>
            <Link to="/workload">
              view workload
            </Link>
          </div>
        </div>
      </div>
      <DashboardSelect
        filterValue={filterValue}
      />
      <AllPendingTable
        selectBox={selectBox}
      />
    </div>
  )
}

export default AllPendingTask





