import React, { useState } from 'react'
import WorkloadWeek from './components/WorkloadWeek'
import DashboardSelect from '../Dashboard/component/DashboardSelect'
import WorkloadFilter from './components/WorkloadFilter'
import { Helmet } from 'react-helmet'

const Workload = () => {
  const [selectBox, setSelectBox] = useState(true);
  const filterValue = (selectValue) => {
    setSelectBox(selectValue)
  }
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Workload | Sample Dashboard</title>
      </Helmet>
      <div className='pb-2'>
        <div className="heading" >
          <h2>Workload</h2>
        </div>
      </div>
      <DashboardSelect
        filterValue={filterValue}
      />
      <WorkloadFilter />
      <WorkloadWeek
        selectBox={selectBox}
      />
    </div>
  )
}

export default Workload
