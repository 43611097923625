

import React, { useState, useEffect } from 'react'
import Box from '../../../component/Box';
import UpcomingTask from './UpcomingTask';
import PendingTable from './PendingTable';
import CompleteTask from './CompleteTask';

const PendinTab = () => {
  const [selectedSegment, setselectedSegment] = useState("current");
  const setSegment = (segment) => {
    setselectedSegment(segment);
  };
  return (
    <div className='pt-2'>
       <Box display="flex" alignItems="center" cursor="pointer" justifyContent="left">
        <Box fontSize="22px" fontWeight={700}>{selectedSegment === "current"}</Box>
        <ul className="d-flex tab_setting">
          <li
            className="current"
            style={{
              fontWeight: selectedSegment === "current" ? "600" : "600",
              backgroundColor:
                selectedSegment === "current" ? "#33DABD" : "#D9D9D9",
              color: selectedSegment === "current" ? "#1C1C1C" : "#1C1C1C",
              marginRight:selectedSegment === "current" ? "7px" : "7px",
              padding:selectedSegment === "current" ? "7px" : "7px",
              borderRadius:
                selectedSegment === "current"
                  ? "5px 5px 0px 0px"
                  : "5px 5px 0px 0px",
            }}
            onClick={() => setSegment("current")}
          >
                  Current Task{" "}
          </li>
          <li
            className="screen-details"
            style={{
              fontWeight: selectedSegment === "upcomming" ? "600" : "600",
              backgroundColor:
                selectedSegment === "upcomming" ? "#33DABD" : "#D9D9D9",
              color: selectedSegment === "upcomming" ? "#1C1C1C" : "#1C1C1C",
              borderRadius:
                selectedSegment === "upcomming"
                  ? "5px 5px 0px 0px"
                  : "5px 5px 0px 0px",
            }}
            onClick={() => setSegment("upcomming")}
          >
           
           Upcoming Task{" "}
          </li>
          <li
            className="screen-details"
            style={{
              fontWeight: selectedSegment === "complete" ? "600" : "600",
              backgroundColor:
                selectedSegment === "complete" ? "#33DABD" : "#D9D9D9",
              color: selectedSegment === "complete" ? "#1C1C1C" : "#1C1C1C",
              borderRadius:
                selectedSegment === "complete"
                  ? "5px 5px 0px 0px"
                  : "5px 5px 0px 0px",
            }}
            onClick={() => setSegment("complete")}
          >
           
           Completed Task{" "}
          </li>
         
       
        </ul>
      </Box>
      <Box className='tab_body'>
        {selectedSegment === "current" ? (
            <PendingTable
            setSegment={setSegment}
            tab={selectedSegment}
            />
        ) : null}
        {selectedSegment === "upcomming" ? (
            <UpcomingTask
            setSegment={setSegment}
            tab={selectedSegment}
            />
        ) : null}
        {selectedSegment === "complete" ? (
            <CompleteTask
            setSegment={setSegment}
            tab={selectedSegment}
            />
        ) : null}
        
    
      </Box>
    </div>
  )
}

export default PendinTab

