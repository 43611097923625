import React, { useState } from 'react'
import SampleCalender from './component/SampleCalender'
import DashboardSelect from '../Dashboard/component/DashboardSelect'
import CalendarSelect from './component/CalendarSelect'
import { Helmet } from 'react-helmet'
import CustomBigCalendar from './component/CustomBigCalendar'


const Calendar = () => {
  const [selectBox, setSelectBox] = useState(true);
  const filterValue = (selectValue) => {
    setSelectBox(selectValue)
  }
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Calendar | Sample Dashboard</title>
      </Helmet>
      <CalendarSelect
      filterValue={filterValue}
      /> 
      {/* <SampleCalender
      selectBox={selectBox}
      /> */}
      <CustomBigCalendar
       selectBox={selectBox}
      />  
     
    </div>
  )
}

export default Calendar
