import React, { useEffect, useState } from 'react'
import UserNameImage from '../../../component/UserNameImage/UserNameImage';

const EmployeeModal = (props) => {
  const [workData, setWorkData] = useState([]);

  useEffect(() => {
    props.workValue(workData)
  }, [workData])

  return (
    <div>
      <div className='persion-flex workload-block'>
        {
          props.userWorkData.map((item, index) => {
            return (
              <>
                <div className="d-flex" key={index}>
                  <UserNameImage
                    name={item.name}
                    image={item.image}
                    width="42px"
                    height="42px"
                  />
                  <div className='margin_left'>
                    <h6>{item.name}-{item.user_id}</h6>
                    <p className='hour_workload'>Current Workload {item.hours}hr</p>
                  </div>
                </div>
                <div className='filter-border mb-2 mt-2'></div>
              </>
            )

          })
        }

      </div>
    </div>
  )
}

export default EmployeeModal
