// AudioPlayer.js
import React, { useState } from 'react';

const AudioPlayer = ({ audioSrc }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = React.createRef();

  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div>
      <audio ref={audioRef} src={audioSrc} />
      <div onClick={handlePlayPause}>
        {isPlaying ? <div className='play_but'><i className="fa fa-pause" aria-hidden="true"></i></div> : <div className='play_but'><i className="fa fa-play" aria-hidden="true"></i></div>}
      </div>
    </div>
  );
};

export default AudioPlayer;
