import React, { useState } from 'react'
import Table from '../../../component/Table/Table';
import { useDispatch, useSelector } from "react-redux";
import { getnodeCriticalityUpdateBulk } from '../../../store/user/actions';

const AssignTable = ({ requestList, removeRequest, ids }) => {
    const [formValues, setFormValues] = useState([])
    const dispatch = useDispatch()
    const nodeCriticalityUpdateBulk = useSelector(state => state.nodeCriticalityUpdateBulk)

    const handleChange = (index, node_group_id, name, value) => {
        const oldValue = { ...formValues }
        if (!oldValue[index]) {
            oldValue[index] = {
                "criticality_id": "", "group_id": node_group_id, "buffer_time": ""
            }
        }
        oldValue[index][name] = value;
        oldValue[index]["group_id"] = node_group_id;
        setFormValues(oldValue);
    }
    const FakeTableData = requestList.data.group_list.map((item, i) =>
        [
            <>
                <h6>{i + 1} </h6>
            </>,
            <div>
                <h6>{item.group_name}</h6>
            </div>,
            <>
            </>,
            <div className="custom-select-table">
                <select className='selectArea assign_select'
                    onChange={(e) => handleChange(i, item.node_group_id, "criticality_id", e.target.value)}
                    defaultValue="">
                    <option value="">Please Select</option>
                    {requestList.data.criticalitylist.map((item) => (
                        <option key={item.id} value={item.id}>{item.name}</option>
                    ))}
                </select>
            </div>,
            <>
            </>,
            <>
                <input
                    placeholder='Enter Buffer Time'
                    className='buffer_input'
                    onChange={(e) => handleChange(i, item.node_group_id, "buffer_time", e.target.value)}
                />
            </>
        ]
    )

    const submitHendle = () => {
        dispatch(getnodeCriticalityUpdateBulk({ request_id: ids, criticality: formValues }))
    }

    const TemplateTableData = [

        {
            heading: "Sr.No.",
        },
        {
            heading: "Node Group Name",
        },
        {
            heading: "",
        },
        {
            heading: "Choose Criticality",
        },
        {
            heading: "",
        },
        {
            heading: "Buffer Time",
        },

    ];

    return (
        <div>
            <div className="position-relative">
                <div className='node_box'>
                    <div className='node-flex'>
                        {requestList.data.list.map((item, i) =>
                            <div className='node-im' key={i}>
                                <div>
                                    <img className='main-img' src={item.samplePicture} />
                                    <img className='close_icon' src="/Images/close_icon.svg" onClick={() => removeRequest(item.id)} />
                                </div>
                                <h6>{item.sampleRequestId}</h6>
                            </div>)}
                    </div>
                </div>

                <div className=''>
                    <Table
                        gridTemplateColumnsHeader="8% 22% 15% 20%  15% 20%"
                        gridTemplateColumnsBody="8% 22% 15% 20% 15% 20%"
                        columns={TemplateTableData}
                        //   data={tableData}
                        data={FakeTableData}
                    />
                </div>
                {requestList.data.list.length > 0 &&
                    <div className="remark-buttons">
                        <button className='dark-but'
                            type="button"
                            onClick={submitHendle}
                        >
                            Submit
                        </button>
                    </div>}
            </div>
        </div>
    )
}

export default AssignTable
