import React, { useState } from 'react'
import LogTable from './component/LogTable'
import LogFilter from './component/LogFilter'

const Logs = () => {
  const [selectBox, setSelectBox] = useState(true);

  const filterValue = (selectValue) => {
    setSelectBox(selectValue)
  }
  return (
    <div>
       <div className="heading">
          <h2>Logs</h2>
        </div>
      <LogFilter
       filterValue={filterValue}
      />
      <LogTable
       selectBox={selectBox}
      />
    </div>
  )
}

export default Logs
