import React, { useEffect, useState, useCallback } from 'react';
import ReactFlow, { Controls, useEdgesState, Position } from 'reactflow';
import { Link, useParams } from 'react-router-dom'
import dayjs from 'dayjs';
import ToggleSwitch from './ToggleSwitch'
import DefaultModal from '../../../component/DefaultModal/DefaultModal';
import DatesModal from './DatesModal';
import moment from 'moment-timezone'
import PersonModal from './PersonModal';
import { useDispatch, useSelector } from 'react-redux';
import { getnodeBufferTimeChange, getnodeDateChange, getnodeLeadTimeChange, getsampleNodeFlowData, getsampleTranNodeTodoConnect } from '../../../store/user/actions';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { DatePicker, Select } from 'antd';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'reactflow/dist/style.css';
import HoldSwitch from './HoldSwitch';
import { resetsampleNodeFlowDataState } from '../../../store/user/userSlice';
import Loader from '../../../component/Loader';
import FullPageLoader from '../../../component/FullPageLoader/FullPageLoader';
// import ButtonEdge from './ButtonEdge';

dayjs.extend(customParseFormat);
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

function Flow({ isInsideModal, trail, trailChange, refreshNode, addNode }) {
  const initialValues = {
    datetime: '',
  };

  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [nodes, setNodes] = useState([])
  const { id } = useParams();
  const [data, setData] = useState([])
  const [boxHeight, setBoxHeight] = useState(100)
  const [show, setShow] = useState(false);
  const [datesBox, setdatesBox] = useState(true);
  const [isModalTwoOpen, setIsModalTwoOpen] = useState(false);
  const [bufferModal, setBufferModal] = useState(false);
  const [criticalityNodal, setCriticalityNodal] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [formValues, setFormValue] = useState(initialValues);
  const [isModalThreeOpen, setIsModalThreeOpen] = useState(false);
  const [userdateData, setuserdatesData] = useState();
  const [selectNode, setSelectNode] = useState(0);
  const [changeDate, setChangeDate] = useState(null);
  const [userId, setUserId] = useState(0);
  const [bufferTime, setBufferTime] = useState(0);
  const [leadTime, setLeadTime] = useState(0);
  const [prevSelectedTrailId, setPrevSelectedTrailId] = useState(null);
  const sampleNodeFlowData = useSelector(state => state.sampleNodeFlowData)
  const nodeBufferTimeChange = useSelector(state => state.nodeBufferTimeChange)
  const nodeLeadTimeChange = useSelector(state => state.nodeLeadTimeChange)
  const nodeDateChange = useSelector(state => state.nodeDateChange)
  const dispatch = useDispatch()

  
  useEffect(() => {
    if(sampleNodeFlowData.isLoading == false){
      dispatch(resetsampleNodeFlowDataState())
      dispatch(getsampleNodeFlowData({ id, trail }))
    }
  }, [trail, refreshNode, nodeBufferTimeChange, nodeLeadTimeChange])
  const refreshNodeData = () => {
    if(sampleNodeFlowData.isLoading == false){
    dispatch(resetsampleNodeFlowDataState())
    dispatch(getsampleNodeFlowData({ id, trail }))
    }
  }
  const datesValue = () => {
    setdatesBox()
  }
  const datesData = (data) => {
    setShow(true)
    setuserdatesData(data)
  }

  const onChange = (value, date) => {
    const timestamp = date
    const tz = "Asia/Kolkata"
    const dayjsLocal = dayjs(timestamp);
    setChangeDate(dayjsLocal.tz(tz, true).format())
  }
  // validation-start
  useEffect(() => {
    if (Object.keys(formErrors).length > 0) {
    }
  }, [formErrors]);

  const handleChange = (name, value) => {
    let data = { ...formValues };
    data[name] = value;
    setFormValue(data);
  };
  const validate = (values) => {
    let isValid = true;
    const errors = {};
    const tomorrow = dayjs().add(1, 'day').startOf('day'); // Tomorrow's date
    const selectedDate = dayjs(values.datetime);
    
    if (!values.datetime) {
      isValid = false;
      errors.datetime = "Enter Date Time";
    } else if (selectedDate.isBefore(tomorrow)) {
      isValid = false;
      errors.datetime = "The datetime must be a date after tomorrow.";
    }
    
    return {
      isValid,
      errors,
    };
  };
  
  const getInitials = (name) => {
    const words = name.split(' ');
    const initials = words.map(word => word[0]);
    return initials.join('');
  };
  const dateChagehandle = (e, name, value) => {
    setError(null);
    setLoading(true);
    let check = validate({ datetime: changeDate });
    setFormErrors(check.errors);
    if (check.isValid) {
      setLoading(false);
      dispatch(getnodeDateChange({ id: selectNode, datetime: changeDate }))
      setIsModalThreeOpen(false)
    }
  }
  useEffect(() => {
    if (nodeDateChange.isSuccess) {
      refreshNodeData()
    }
  }, [nodeDateChange])

  useEffect(() => {
    setData(sampleNodeFlowData.data)
  }, [sampleNodeFlowData])
  useEffect(() => {
    
    setNodes([]);
    const group = [];
    var edgesgroup = [];
    var yaxis = 100;
    var i = 1
    if (data == null || data == undefined) {
      return
    }
    
    for (let [trail, node] of Object.entries(data)) {
      group.push({
        id: "Trail-" + i,
        type: "group",
        trailId: trail,
        position: { x: 0, y: yaxis },
        style: {
          width: 1110,
          height: 350,
          borderWidth: 1,
          backgroundColor: 'rgba(240,240,240,0.25)',
          boxShadow: "0 1rem 3rem rgba(0,0,0,.175)",
          paddingRight: 15,
          paddingLeft: 15
        },
      }
      )

      group.push({
        id: 'TrailTitle-' + trail,
        data: { label: '' + trail },
        trailId: trail,
        position: { x: 10, y: 5 },
        parentNode: "Trail-" + i,
        extent: 'parent',
      })
      var nodexaxis = 10;
      node.map((nodeData) => {
        group.push({
          id: "nodeGroup" + trail + nodeData.id,
          type: "group",
          position: { x: nodexaxis, y: 60 },
          trailId: trail,
          parentNode: "Trail-" + i,
          extent: 'parent',
          selected: false,
          className: 'light',
          style: {
            width: 150,
            height: 280,
            backgroundColor: 'rgba(240,240,240,0.25)',
            boxShadow: "0 1rem 3rem rgba(0,0,0,.175)",
            paddingRight: 0,
            paddingLeft: 0,
            borderWidth: 0
          },
        })
        nodexaxis += 220
        group.push({
          id: "N" + nodeData.id,
          // type: "input",
          nodeId: nodeData.id,
          trailId: trail,
          userId: nodeData.user.id,
          data: {
            label: <div className='trail-col' onNodeClick={onElementClick}>
              {(nodeData.hold == 1 && nodeData.hold_datetime != null) ? <div className="blackwhite_status trail-img"><img src={nodeData.user.profile_image_thumbnail == "" ? "/Images/dummy.jpg" : process.env.REACT_APP_IMG_URL+nodeData.user.profile_image_thumbnail} /> <div className='trail-status-hold'><span>Hold for Rejection</span></div></div> :
                (nodeData.hold == 1 && nodeData.hold_datetime == null) ? <div className="blackwhite_status trail-img" ><img src={nodeData.user.profile_image_thumbnail == "" ? "/Images/dummy.jpg" : process.env.REACT_APP_IMG_URL+nodeData.user.profile_image_thumbnail} /> <div className='trail-status-hold'><span>Hold</span></div></div> :
                  <div className={nodeData.status != 1 ? "inactiveNode" : nodeData.node_status == "green" ? 'trail-green trail-img' : nodeData.node_status == "red" ? 'trail-red trail-img' : nodeData.node_status == "orange" ? 'trail-orange trail-img' : nodeData.node_status == "blackwhite" ? 'trail-blackwhite trail-img' : ""}>
                    {nodeData.user.profile_image_thumbnail == "" ? <div className='userTextImageBox'>{getInitials(nodeData.user.firstname + " " + nodeData.user.lastname)}</div> :
                      <img src={nodeData.user.profile_image_thumbnail == "" ? "/Images/dummy.jpg" :process.env.REACT_APP_IMG_URL+nodeData.user.profile_image_thumbnail} />
                    }
                    <h6 className='trail-status'><span>{nodeData.node_message}</span></h6>
                  </div>}

            </div>
          },
          position: { x: 0, y: 0 },
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
          parentNode: "nodeGroup" + trail + nodeData.id,
          extent: 'parent',
          className: 'light',
          style: {
            paddingRight: 0,
            paddingLeft: 0,
            borderWidth: 0
          }
        })
       const edgeColor = []
        group.push({
          id: "nodebody" + nodeData.id,
          type: "input",
          trailId: trail,
          data: {
            label: <div className='traill-text'>
              <h5 className='pt-2' title={nodeData.user.firstname + " " + nodeData.user.lastname + "-" + nodeData.user.id}>{nodeData.user.firstname + " " + nodeData.user.lastname + "-" + nodeData.user.id}</h5>
              <h5 className='pb-1'>{nodeData.node.name}</h5>
              <h6>LT-{nodeData.sample_process_leadtime}hrs | BT-{nodeData.buffer_time}mins</h6>
              {isInsideModal && (
                <div className='view_date_but'>
                  <Link
                    className={nodeData.node_status == "blackwhite" ? 'trail-blackwhite trail-img' : ""}
                    onClick={() => datesData(nodeData)}
                  >
                    View Dates
                  </Link>
                </div>
              )}

              {!isInsideModal && (
                <div className='view_modal_hide_text'>
                  <div>
                    <div className='d-flex justify-content-center'>
                    <ToggleSwitch
                      id={nodeData.id}
                      refreshNodeData={refreshNodeData}
                      status={nodeData.status == 1}
                      label={'t' + nodeData.id} />
                      <div className="bg-image-sm">
                    <div class="team-pic">
                      <div className="team-member">
                        {/* <Link className='setting_a'>Setting</Link> */}
                        <img src="/Images/setting_icn.svg" className='setting_icn'/>
                        <span class="tooltiptext-sm flow_tooltip">
                          <Link
                            // nodeData.status!=1?"inactiveNode": 
                            // className={nodeData.node_status == "blackwhite" ? 'trail-blackwhite trail-img' : ""}
                            onClick={() => datesData(nodeData)}
                          >View Dates</Link>
                          <div className='' onClick={() => { setIsModalThreeOpen(true); setSelectNode(nodeData.id) }}><Link>Change Date</Link></div>
                          <Link onClick={() => { setBufferModal(true); setSelectNode(nodeData.id) }}>Change Buffer Time</Link>
                          <Link onClick={() => { setCriticalityNodal(true); setSelectNode(nodeData.id) }}>Change Lead Time</Link>
                          <div className='hold_switch'>
                            <Link>Hold/UnHold</Link>
                            <HoldSwitch 
                            label={'h' + nodeData.id} 
                            id={nodeData.id}
                            status={!(nodeData.hold == 1 && nodeData.hold_datetime == null)}
                            refreshNodeData={refreshNodeData} /></div>

                        </span>
                      </div>
                    </div>
                      </div>
                      </div>
                      <div>
                        <ul className='edge_list'>
                          {nodeData.todos.slice(0, 2).map((edge, index) => {
                            edgeColor.push(generateRandomColor())
                            const isBlack = trailCheck(trail, edge.sample_category_node_transactions_id) === 1;
                            return(
                            <li
                              className={`gray-circle-edge ${
                                index === 0 ? 'bottom-left' : index === 1 ? 'bottom-right' : ''
                              }`}
                              key={edge.id}
                              style={{ backgroundColor: isBlack ? "#3F4A59" : edgeColor[index] }}
                            >
                              {index === 0 ? "&" : index === 1 ? "|" : ""}
                            </li>
                          )})}
                          </ul>
                      </div>
                    <div className='d-flex justify-content-center mt-2'>
                      <div className='trail-button-o'>
                        <span style={{ color: "#33DABD" }}>{nodeData.reward_point}</span><span style={{ color: "#DEDEDE" }}>|</span><span style={{ color: "#F00" }}>{nodeData.strike_point}</span>
                      </div>
                      <div className='trail-button-green'>
                        ₹{nodeData.incentive}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          },
          position: { x: 0, y: 160 },
          sourcePosition: Position.Right,
          targetPosition: Position.Left,
          parentNode: "nodeGroup" + trail + nodeData.id,
          extent: 'parent',
          className: 'light',
        })
        var tempedgesgroup = nodeData.todos.map((edge,index) => {
          var tata = [...edgesgroup]
          const isBlack = trailCheck(trail, edge.sample_category_node_transactions_id) === 1;
          
          tata.push({
           
            id: "e-" + trail + nodeData.id,
            source: "N" + edge.todo_after,
            target: "N" + edge.sample_category_node_transactions_id,
            animated: true, // Set animated property to true for the smoothstep effect
            // label: edge.type == 1 ? "AND" : "OR",
            style: {
              stroke: isBlack ? "#3F4A59" : edgeColor[index],
              strokeWidth: isBlack ? '2px' : '4px',
              strokeDasharray: 'none',
              // arrowHeadType: 'none',
            },
            data: { label: edge.type, id: edge.id, status: edge.type == 1 },
            type: 'smoothstep'
        
        });
         
           
          edgesgroup = tata
          setEdges(tata)
        })

      })
      {!isInsideModal && ( group.push({
        id: "N-new" + i,
        // type: "input",
        trailId: trail,
        data: {
          label: <div className='w-100'>
            <div className='node-default'>
              <div className='circle-bg' onClick={() => addNode(true)}>
                <img src='/Images/plus.svg' />
              </div>
            </div>
          </div>
        },
        position: { x: nodexaxis, y: 60 },
        sourcePosition: Position.Right,
        targetPosition: Position.Left,
        parentNode: "Trail-" + i,
        extent: 'parent',
        className: 'light',
        style: {
          paddingRight: 0,
          paddingLeft: 0,
          borderWidth: 0
        }
      })
      )}
      yaxis += 500;

      i++;
    }
    setBoxHeight(yaxis + 100)
    setNodes(group)
  }, [data])


  const onElementClick = (event, object) => {
    if (object.nodeId) {
      setIsModalTwoOpen(true)
      setSelectNode(object.nodeId)
      setUserId(object.userId)
    }

    const selectedTrailId = object.trailId;

    setNodes((prevNodes) =>
      prevNodes.map((node) => {
        if (node.id.startsWith('Trail-')) {
          // Remove 'selected' class from the previously selected trail
          if (node.trailId === prevSelectedTrailId) {
            return {
              ...node,
              className: 'notselected',
            };
          }
          // Toggle the 'selected' class for the clicked trail
          if (node.trailId === selectedTrailId) {
            return {
              ...node,
              className: 'selected',
            };
          }
        }
        return node;
      })
    );

    setPrevSelectedTrailId(selectedTrailId);
    trailChange(selectedTrailId);
  }

  const disabledDate = (current) => {
    // Disable dates before today
    return current && current < new Date();
  };
  const trailCheck = (trail, id) => {
    const nodelist = data[trail].filter(item => item.id == id);
    return nodelist.length;
  }
  const generateRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    
    return color;
  };

  const onConnect = useCallback((params) => {
    if (params.source.startsWith("N-new") || params.target.startsWith("N-new")) {
      alert("Connected with not a node")
    } else {
      const source = params.source.replace("N", "")
      const target = params.target.replace("N", "")
      dispatch(getsampleTranNodeTodoConnect({ source, target }))
      refreshNodeData()
    }
  }, [],);
  const bufferTimeSubmit = () => {
    dispatch(getnodeBufferTimeChange({ id: selectNode, buffer_time: bufferTime }))
    setBufferModal(false)
  }
  const leadTimeSubmit = () => {
    dispatch(getnodeLeadTimeChange({ id: selectNode, lead_time: leadTime }))
    setCriticalityNodal(false)
  }

  return (
    <div className='position-relative'>
       {
          sampleNodeFlowData.isLoading && <div className="loader_bg"><FullPageLoader /></div>
           }
      <div className="flow-section flow-section-category" style={{ height: boxHeight + 'px' }}>
      
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodeClick={onElementClick}
          onConnect={onConnect}
          className="react-flow-subflows-example"    
        >
          <Controls />
        </ReactFlow>
      </div>
      <DefaultModal
        show={show}
        onHide={() => setShow(false)}
        className="dates-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>Dates</h4>
          <div className="close-icon">
            <img src="/Images/closeBlack.svg" onClick={() => setShow(false)} />
          </div>
        </div>
        <DatesModal
          datesValue={datesValue}
          userdateData={userdateData}
        />

      </DefaultModal>
      <DefaultModal
        show={isModalTwoOpen}
        onHide={() => setIsModalTwoOpen(false)}
        className="person-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>RESPONSIVE PERSON</h4>
          <div className="close-icon">
            <img src="/Images/closeBlack.svg" onClick={() => setIsModalTwoOpen(false)} />
          </div>
        </div>
        <div className='heading-border'></div>

        <PersonModal
          // nodeChange={nodeChange}
          viewPersonModal={setIsModalTwoOpen}
          selectNode={selectNode}
          assignuserId={userId}
          refreshNodeData={refreshNodeData}
        />
      </DefaultModal>
      <DefaultModal
        show={isModalThreeOpen}
        onHide={() => setIsModalThreeOpen(false)}
        className="person-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>Change Date</h4>
          <div className="close-icon">
            <img src="/Images/closeBlack.svg" onClick={() => setIsModalThreeOpen(false)} />
          </div>
        </div>


        <div>
          <label>Date Time </label>
          <DatePicker
            className="sub-input-box"
            format="YYYY-MM-DD HH:mm:ss"
            showTime={{ defaultValue: dayjs('00:00:00', 'HH:mm:ss') }}
            //  showTime={{default: moment.tz('Asia/Kolkata').startOf('day'),}}
            onChange={(e) => onChange('datetime', e)}
            disabledDate={disabledDate}
          />
          <span className='error-msg' style={{ color: 'red', fontSize: '12px' }}>{formErrors.datetime}</span>
          <div className='but_sec'>
            <button className='dark-but'
              type="button"
              onClick={() => dateChagehandle()}
            >
              Submit
            </button>
          </div>
        </div>
      </DefaultModal>
      <DefaultModal
        show={bufferModal}
        onHide={() => setBufferModal(false)}
        className="person-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>Change Buffer Time</h4>
          <div className="close-icon">
            <img src="/Images/closeBlack.svg" onClick={() => setBufferModal(false)} />
          </div>
        </div>

        <div className='mt-4'>
          <h6>Enter Buffer time</h6>
          <input
            placeholder='Enter Buffer time in minutes'
            className='input-custom form-control'
            onChange={(e) => setBufferTime(e.target.value)}
            style={{
              height: "48px",
              width: "100%",
            }}
          />
        </div>
        <div className='mx-auto text-center'>
          <button className='dark-but mt-4' type="button" onClick={() => bufferTimeSubmit()}>
            Submit
          </button>
        </div>
      </DefaultModal>
      <DefaultModal
        show={criticalityNodal}
        onHide={() => setCriticalityNodal(false)}
        className="person-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>Change Lead Time</h4>
          <div className="close-icon">
            <img src="/Images/closeBlack.svg" onClick={() => setCriticalityNodal(false)} />
          </div>
        </div>
        <div className='mt-4'>
          <h6>Enter Lead time</h6>
          <input
            placeholder='Enter Lead time in Hrs'
            className='input-custom form-control'
            onChange={(e) => setLeadTime(e.target.value)}
            style={{
              height: "48px",
              width: "100%",
            }}
          />
        </div>
        <div className='mx-auto text-center'>
          <button className='dark-but mt-4' type="button" onClick={() => leadTimeSubmit()}>
            Submit
          </button>
        </div>

      </DefaultModal>

    </div>
  );
}

export default Flow;
