import React, { useState } from 'react'
import EmployeeModal from './EmployeeModal'
import DefaultModal from '../../../component/DefaultModal/DefaultModal'

const WorkloadFilter = () => {
  const [show, setShow] = useState(false);
  return (
    <div className='filter-workload'>
      <DefaultModal
        show={show}
        onHide={() => setShow(false)}
        className="person-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>WORKLOAD</h4>
          <div className="close-icon">
            <img src="/Images/closeBlack.svg" onClick={() => setShow(false)} alt="" />
          </div>
        </div>

        <EmployeeModal />
      </DefaultModal>
    </div>
  )
}

export default WorkloadFilter
