import { Pagination } from 'antd';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Table from '../../../component/Table/Table';
import { getrejectionList } from '../../../store/user/actions';
import { useDispatch, useSelector } from 'react-redux';
import DefaultModal from '../../../component/DefaultModal/DefaultModal';
import SubmissonModal from './ViewSubmissonModal';
import ViewSubmissonModal from './ViewSubmissonModal';
import Loader from '../../../component/Loader';

const RejectionTable = ({selectBox}) => {
    const [current, setCurrent] = useState(1);
    const [total, setTotal] = useState(0);
    const [id, setId] = useState(0);
    const [trainId, setTrainId] = useState(0);
    const [status, setStatus] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [showViewNode, setViewNode] = useState(false);
    const rejectionList = useSelector(state => state.rejectionList)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getrejectionList({ page: current,selectBox }))
    }, [current,selectBox])

    const refreshdata = () =>{
        dispatch(getrejectionList({ page: current,selectBox }))
    }

    const onChange = (page) => {
        setCurrent(page);
        dispatch(getrejectionList({...selectBox,page}))
    };

    useEffect(() => {
        setTotal(rejectionList.data.total)
        let sno = (current - 1) * 10 + 1;
        const FakeTableData = rejectionList.data.data.map((item, i) =>
            [
                <>
                    <h6>{sno + i} </h6>
                </>,
                <>
                    <h6>{item.SampleId}</h6>
                </>,
                <div className="pending-table-img">
                    <img src={process.env.REACT_APP_IMG_URL+item.image} />
                    <h6>{item.taskName}</h6>
                </div>,
                <>
                    <h6>{item.collectionName}</h6>
                </>,
                <>

                    <h6>{item.nextTask}</h6>
                </>,
                <>
                    <h6>{item.planDate}</h6>
                </>,
                <>
                    <h6>{item.delay} Days</h6>
                </>,
                <>
                    <h6>{item.currentPlanDate}</h6>
                </>,
                <>
                    <h6>{item.rejectionFiledOn}</h6>
                </>,
                <div className='currnt-nodes'>
                    <img src={process.env.REACT_APP_IMG_URL+item.rejectionFiledUserImage} />
                    <h6>{item.rejectionFiledUserName}</h6>
                </div>,
                <>
                    <h6 className={item.status == 0 ? "Requestpending" : item.status == 1 ? "RequestAccepted" : item.status == 2 ? "RequestDecline" : ""}>{item.status == 0 ? "Request pending" : item.status == 1 ? "Request Accepted" : item.status == 2 ? "Request Decline" : ""}</h6>
                </>,
                <div className="text-center mx-auto">
                    <img src="/Images/notification.svg" />
                </div>,
                <div className='sample-but'>
                    <Link onClick={() => { setStatus(item.status); setTrainId(item.id); setViewNode(true); setId(item.rejectionId) }}>View</Link>
                </div>
            ],
        );
        setTableData(FakeTableData)
    }, [rejectionList])

    const TemplateTableData = [

        {
            heading: "Sr.No.",
        },
        {
            heading: "Id",
        },
        {
            heading: "Tasks",
        },
        {
            heading: "Collections Name",
        },
        {
            heading: "Next Task",
        },
        {
            heading: "Planed Date",
        },
        {
            heading: "Delay",
        },
        {
            heading: "Current Planed Date",
        },
        {
            heading: "Rejection Filed On",
        },
        {
            heading: "Rejection Filed by",
        },
        {
            heading: "Current Status",
        },
        {
            heading: "Notify",
        },
        {
            heading: "Actions",
        },

    ];
    return (
        <div className='position-telative'>
            {
          rejectionList.isLoading && <div className="loader_bg"><Loader /></div>
           }
            <div className='table-bg'>
                <Table
                    gridTemplateColumnsHeader="6% 7% 10% 8% 8% 7% 5% 8% 8% 8% 7% 7% 8%"
                    gridTemplateColumnsBody="6% 7% 10% 8% 8% 7% 5% 8% 8% 8% 7% 7% 8%"
                    columns={TemplateTableData}
                    data={tableData}
                />
                <Pagination
                    current={current}
                    onChange={onChange}
                    total={total} />
            </div>
            <DefaultModal
                show={showViewNode}
                onHide={() => setViewNode(false)}
                className="view_node_modal"
                style={{ paddingRight: "0px" }}
                centered={false}
                showHeader={false}
                size=""
                showFooter={false}
            >
                <div className="modal-heading">
                    <h4>Submission</h4>
                    <div className="close-icon">
                        <img src="/Images/closeBlack.svg" onClick={() => setViewNode(false)} />
                    </div>
                </div>
                <div className='heading-border'></div>

                <ViewSubmissonModal id={id} trainId={trainId} status={status} setViewNode={setViewNode} refreshdata={refreshdata}/>
            </DefaultModal>
        </div>
    )
}

export default RejectionTable
