import React from 'react';
import './splash.css'
import { Helmet } from 'react-helmet';
const SplashPage = () => {
    return (
        <div className='splash-bg'>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Splash | Sample Dashboard</title>
            </Helmet>
            <img src="Images/splash-logo.svg" />
            <h3>Sample Management</h3>
        </div>
    );
};

export default SplashPage;
