import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getstatusSampleNode } from '../../../store/user/actions';
import { showNotification } from '../../../util/utility';

const ToggleSwitch = ({ label,status,id,refreshNodeData }) => {
  const [isChecked, setChecked] = useState(status);
  const statusSampleNode = useSelector(state => state.statusSampleNode)
  const dispatch = useDispatch()
  const resetCheckbox = () => {
    // if (status === 1) {
      const newStatus = isChecked ? 0 : 1;
      dispatch(getstatusSampleNode({ id, status: newStatus }));
      setChecked(!isChecked);
    // }else {
    //   showNotification("error","You are not allowed to access this api")
    // }
  };
  useEffect( () => {
    if (statusSampleNode.isSuccess){
      setTimeout(()=>{
      refreshNodeData()
      },2000)
    }
    },[statusSampleNode])
  return (
    <div className="custom-switch">
      {/* {/ <p>{label}{" "} </p> /} */}
      <div className="toggle-switch">
        <input type="checkbox" className="checkbox"
          name={label} id={label}
          checked={isChecked}
          onChange={resetCheckbox}
          disabled={status === 0}
          />
        <label className="label" htmlFor={label}>
          <span className="inner" />
          <span className="switch" />
        </label>
      </div>
     
    </div>
  );
};

export default ToggleSwitch;

