import React, { useEffect } from 'react'
import { cardData } from '../../../constants/dashboardjson'
import { useDispatch, useSelector } from 'react-redux'
import { getdashboardCard } from '../../../store/user/actions'

const DashboardCard = () => {
    const dashboardCard = useSelector(state => state.dashboardCard.data)
    const dispatch = useDispatch()
    const optionsSelect = [
        {
          value: "Today",
          lable: "Today"
        },
        {
          value: "This Week",
          lable: "This Week"
        },
        {
          value: "Last Week",
          lable: "Last Week"
        },
        {
          value: "This Month",
          lable: "This Month"
        },
        {
          value: "Last Month",
          lable: "Last Month"
        },
        {
          value: "Last 3 Month",
          lable: "Last 3 Month"
        },
      ]
      const persionValue =(value) =>{
        dispatch(getdashboardCard({period:value}))
      }
    useEffect(() => {
        dispatch(getdashboardCard())
    }, [])

    return (
        <div className=''>
            <div className='heading_select'>
          <h2> Dashboard</h2>
          <div className="custom-select">
          <select className='selectArea' 
            onChange={(e)=> persionValue(e.target.value)} >
              {
                optionsSelect && optionsSelect.map((item)=>(
                  <option value={item.value}>{item.lable}</option>
                ))
              }
            </select>
       </div>
      </div>
            <div className='row padding_left'>
                {/* {
                cardData && cardData.map((item, i) =>( */}

                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="pale-red">
                        <h2>{dashboardCard.totalCollection}</h2>
                        <p>Total Collections</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="alice-blue">
                        <h2>{dashboardCard.totalCompletedCollection}</h2>
                        <p>Completed Collections</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="light-green">
                        <h2>{dashboardCard.totalPandingCollection}</h2>
                        <p>Pending Collections</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="light-orange">
                        <h2>{dashboardCard.totalPartiallyPandingCollection}</h2>
                        <p>Partially Pending Collections</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="light-pink">
                        <h2>{dashboardCard.totalSampleRequestTime} Days</h2>
                        <p>Sample Request Completed Time</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="dark-green">
                        <h2>{dashboardCard.totalSampleRequest}</h2>
                        <p>Total Samples</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="dark-gray">
                        <h2>{dashboardCard.totalCompleteSampleRequest}</h2>
                        <p>Total Complete Samples</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="light-pitch">
                        <h2>{dashboardCard.totalPandingSampleRequest}</h2>
                        <p>Total Pending Samples</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="dark-brown">
                        <h2>{dashboardCard.totalDetalySampleRequest}</h2>
                        <p>Total Delay Samples</p>
                    </div>
                </div>
                {/* ))
            } */}

            </div>

        </div>
    )
}

export default DashboardCard
