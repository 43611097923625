import React from 'react'
import { employeeData } from '../../../constants/collectionjson'


function TaskProfile() {
    return (
        <div className='padding-section'>
            <div className='row'>
                {
                    employeeData && employeeData.map((item, i) => (
                        <div className='col'>
                            <div className='fllter-con' style={{ width: "80px" }}>
                                <img src={item.image} />
                                <h6 className='pt-2'>{item.name}</h6>
                            </div>
                        </div>
                    ))
                }

            </div>
        </div>
    )
}

export default TaskProfile
