import React, { useEffect, useState } from 'react'
import Table from '../../../component/Table/Table';
import AudioPlayer from '../../ViewSubmissionDetails/component/AudioPlayer';
import { useDispatch, useSelector } from 'react-redux';
import { getpreNodeSubmission } from '../../../store/user/actions';
import { Link, useParams } from 'react-router-dom';
import UserNameImage from '../../../component/UserNameImage/UserNameImage';

const ViewPrevNodeModal = ({trainId}) => {
    const [tableData, setTableData] = useState([]);
    const [current, setCurrent] = useState(1);
    const preNodeSubmission = useSelector((state) => state.preNodeSubmission);
    const dispatch = useDispatch();
   
      useEffect(() => {
        dispatch(getpreNodeSubmission(trainId));
      }, []);
   

    useEffect(()=>{
       
        const FakeTableData = preNodeSubmission && preNodeSubmission.data.map((item, i) => {
          let sno = (current-1)*10+1;
          return [
            <>
            <h6>{sno+i} </h6>
          </>,
            <div className='currnt-nodes'>
            <UserNameImage width='40px' height='40px' margin='0 auto' name={item.user_name} image={item.user_image} />
            <h6>{item.node_name}</h6>
            <h6>{item.user_name}</h6>
          </div>,
            <h6>{item.submit_datetime}</h6>,
            <div>
              <h6> {item.delay}Days</h6>
            </div>,
            <div className='view_im'>
              <img src={item.file} alt='im' />
            </div>,
            <div className='audo_io'>
              <AudioPlayer audioSrc={item.audio} />
            </div>,
            <h6>{item.remark}</h6>,
            <div className='view_a'>
              <Link to={process.env.REACT_APP_WEB_URL + '/download_submission_document/' + item.id} target='blank'>
                View
              </Link>
            </div>,
    
          
          ]
        })
        setTableData(FakeTableData);
      },[preNodeSubmission])

      const TemplateTableData = [
        { heading:'Sr. No.'},
        { heading: 'Username' },
        { heading: 'Submit Date' },
        { heading: 'Delay Time' },
        { heading: 'Image' },
        { heading: 'Voice Note' },
        { heading: 'Remark' },
        { heading: 'Document' },
      ];
  return (
    <div>
       <div className=''>
        <Table
          gridTemplateColumnsHeader='10% 15% 10% 10% 20% 10% 15% 10%'
              gridTemplateColumnsBody='10% 15% 10% 10% 20% 10% 15% 10%'
          columns={TemplateTableData}
          data={tableData}
        />
        {/* <Pagination
          current={current}
          onChange={onChange}
          total={totalData} /> */}
      </div>
    </div>
  )
}

export default ViewPrevNodeModal

