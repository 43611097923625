import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getnodeStatusChange } from '../../../store/user/actions';

const HoldSwitch = ({ label,status,id,refreshNodeData }) => {
    const [isChecked, setChecked] = useState(status);
    const nodeStatusChange = useSelector(state => state.nodeStatusChange)

    const dispatch = useDispatch()

    const resetCheckbox = () => {
      if (isChecked==true) {
        dispatch(getnodeStatusChange({id,status:"hold"}))
      }else {
        dispatch(getnodeStatusChange({id,status:"unhold"}))
      }
       setChecked(!isChecked)
    };
    useEffect( () => {
      if (nodeStatusChange.isSuccess){
        refreshNodeData()
      }
      },[nodeStatusChange])
  return (
    <div>
      <div className="custom-switch">
      {/* <p>{label}{" "} </p> */}
      <div className="toggle-switch">
        <input type="checkbox" className="checkbox"
          name={label} id={label}
          checked={isChecked}
          onChange={resetCheckbox}
      
          // {status?"":"disable"}
          // disabled={!isChecked}
          />
        <label className="label" htmlFor={label}>
          <span className="innerHold" />
          <span className="switch" />
        </label>
      </div>
    </div>
    </div>
  )
}

export default HoldSwitch
