import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import {getToken, getUser} from '../../util/Common'
import { showNotification } from "../../util/utility";



const handleApiRequest = async (url, data = {},notification = false,datamessage = false) => {
  try {
    const token = await getToken()
    const headers = {
      'Authorization': 'Bearer ' + token,
    };
    
    const response = await axios.post(url, data, { headers });
    if (response.data.status === 1) {
      notification && showNotification("success", response.data.message);
      return response.data.data;
    } else  if (response.data.status === 200) {
      notification && showNotification("success", response.data.message);
      return response.data.data;
    } else {
      if(notification && datamessage && response.data.message!="You are not allowed to access this api"){
         showNotification("error", response.data.data.error);
      }else if(notification){
        if(response.data.message=="You are not allowed to access this api"){
          showNotification("error", "User not allowed to do this operation");
        }else{
          showNotification("error", response.data.message);
        }
        
      }else {showNotification("error", response.data.data.error);}
      
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getdashboardCard = createAsyncThunk('dashboardCard/getdashboardCard', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/dashboard`, formValues);
});

export const getcollectionData = createAsyncThunk('collectionData/getcollectionData', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/request-list`, formValues);
});

export const getfilterData = createAsyncThunk('filterData/getfilterData', async (formValues) => {
 
   return handleApiRequest(process.env.REACT_APP_API_URL+`/filter`,formValues);
   
})
export const getpendingData = createAsyncThunk('pendingData/getpendingData', async (formValues) => {
    return handleApiRequest(process.env.REACT_APP_API_URL+`/panding-task`,formValues);
  
})
export const getallpendingData = createAsyncThunk('allpendingData/getallpendingData', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL+`/all-panding-task`,formValues);
  
}) 
export const getworkloadData = createAsyncThunk('workloadData/getworkloadData', async (formValues) => {
    const user = getUser()
    return handleApiRequest(process.env.REACT_APP_API_URL+`/workload/`+user.id,formValues);
}) 

export const getworkloadNodeData = createAsyncThunk('workloadNodeData/getworkloadNodeData', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL+`/workload`,formValues);
}) 

export const getcollectionDelay = createAsyncThunk('collectionDelay/getcollectionDelay', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL+`/collection-delay`,formValues);
   
}) 
export const getcollectionGraph = createAsyncThunk('collectionGraph/getcollectionGraph', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL+`/collection-graph`,formValues);
   
})  
export const getsampleNodeData = createAsyncThunk('sampleNodeData/getsampleNodeData', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/sample-node-list`,formValues);
}) 
export const getremarkData = createAsyncThunk('remarkData/getremarkData', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL+`/remark`,formValues,true);
})
export const gethistoryData = createAsyncThunk('historyData/gethistoryData', async (id) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/remark/list/`+id,{});
}) 
export const getinactiveNode = createAsyncThunk('inactiveNode/getinactiveNode', async (id) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/inactive-node/`+id,{},true);
})
export const getactiveNode = createAsyncThunk('activeNode/getactiveNode', async (id) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/active-node/`+id,{},true);
})
export const getcategoryNode = createAsyncThunk('categoryNode/getcategoryNode', async (formValues,) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/sample-category-node/`+formValues.id,{trail:formValues.trail});
})
export const gettrailData = createAsyncThunk('trailData/getstrailData', async (id) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/get-trail/`+id,{});
}) 
export const getcreateTrailData = createAsyncThunk('createTrailData/getcreateTrailData', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/create-trail`,formValues,true);
}) 
export const getaddNode = createAsyncThunk('addNode/getaddNode', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/add-node`,formValues,true,true);
}) 
export const getresponsiblePerson = createAsyncThunk('responsiblePerson/getresponsiblePerson', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/responsible-person-change/`+formValues.nodeId+'/'+formValues.userId,{},true,true);
}) 
export const getsubmissionData = createAsyncThunk('submissionData/getsubmissionData', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/submission-data`,formValues);
}) 
export const getsampleNodeFlowData = createAsyncThunk('sampleNodeFlowData/getsampleNodeFlowData', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/sample-node`,formValues);
}) 
export const getassignNodeData = createAsyncThunk('assignNodeData/getassignNodeData', async (id) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/assign_node/`+id);
}) 
export const getnodeFormData = createAsyncThunk('nodeFormData/getnodeFormData', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/assign_node`,formValues,true);
}) 
export const getprevReSubmission = createAsyncThunk('prevReSubmission/getprevReSubmission', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/prev-re-submission`,formValues,true);
}) 
export const getmonthPending = createAsyncThunk('monthPending/getmonthPending', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/month-panding`,formValues);
}) 
export const getcalendarData = createAsyncThunk('calendarData/getcalendarData', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/calender`,formValues);
}) 
export const getnodeDateChange = createAsyncThunk('nodeDateChange/nodeDateChange', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/node-date-change`,formValues,true,true);
})
export const getnodeSubmitData = createAsyncThunk('nodeSubmitData/nodeSubmitData', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/node_submit`,formValues,true,true);
})
export const getstatusSampleNode = createAsyncThunk('statusSampleNode/statusSampleNode', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/sample_node_status`,formValues,true);
})
export const getsampleNodeEdgeType = createAsyncThunk('sampleNodeEdgeType/sampleNodeEdgeType', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/sample_node_todo_type`,formValues,true);
})
export const getsampleNodeEdgeConnect = createAsyncThunk('sampleNodeEdgeConnect/sampleNodeEdgeConnect', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/sample_node_todo_connect`,formValues,true);
})
export const getpersionWiseData = createAsyncThunk('persionWiseData/persionWiseData', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/person_wise_workload`,formValues);
})
export const getnodeWiseCompletions = createAsyncThunk('nodeWiseCompletions/nodeWiseCompletions', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/node_wise_completions`,formValues);
})
export const getcollectionWiseCompletion = createAsyncThunk('collectionWiseCompletion/collectionWiseCompletion', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/collection_wise_completion`,formValues);
})
export const getuserlistWithWorkload = createAsyncThunk('userlistWithWorkload/userlistWithWorkload', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/userlist_with_worload/`+formValues.selectNode,formValues);
})
export const getsubmissionDataDetails = createAsyncThunk('submissionDataDetails/getsubmissionDataDetails', async (id) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/submission-data/`+id,true);
}) 
export const getrequestList = createAsyncThunk('requestList/getrequestList', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/request-list-detail`,formValues,true);
}) 
export const getuserListByDesignation = createAsyncThunk('userListByDesignation/getuserListByDesignation', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/user_list_by_designation`,formValues);
}) 
export const getuserWorkLoadData = createAsyncThunk('userWorkLoadData/getuserWorkLoadData', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/user_work_load_data/`+formValues.id,formValues);
}) 
export const getnodeUserUpdateBulk = createAsyncThunk('nodeUserUpdateBulk/getnodeUserUpdateBulk', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/node_user_update_bulk`,formValues,true);
}) 

export const getnewCalendar = createAsyncThunk('newCalendar/getnewCalendar', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/new_calendar`,formValues);
}) 

export const getnodeCriticalityUpdateBulk = createAsyncThunk('nodeCriticalityUpdateBulk/getnodeCriticalityUpdateBulk', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/nodegroup_criticality_update_bulk`,formValues,true);
}) 

export const getsampleLog = createAsyncThunk('sampleLog/getsampleLog', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/sample_log`,formValues);
}) 
export const getresubmissionRequiestStatus = createAsyncThunk('resubmissionRequiestStatus/getresubmissionRequiestStatus', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/resubmission_requiest_status`,formValues,true);
}) 

export const getrejectionList = createAsyncThunk('rejectionList/getrejectionList', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/resubmission_requiest`,formValues);
}) 
export const getpreNodeSubmission = createAsyncThunk('preNodeSubmission/getpreNodeSubmission', async (id) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/pre_node_submission/`+id);
}) 
export const getcurrentNodeSubmission = createAsyncThunk('currentNodeSubmission/getcurrentNodeSubmission', async (id) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/current-node-submission/`+id);
}) 
export const getcriticalityList = createAsyncThunk('criticalityList/getcriticalityList', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/criticality_list`,formValues);
}) 

export const getaddTranNode = createAsyncThunk('addTranNode/getaddTranNode', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/add_tran_node`,formValues,true);
}) 

export const getsampleTranNodeTodoConnect = createAsyncThunk('sampleTranNodeTodoConnect/getsampleTranNodeTodoConnect', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/sample_tran_node_todo_connect`,formValues,true);
})

export const getnodeBufferTimeChange = createAsyncThunk('nodeBufferTimeChange/getnodeBufferTimeChange', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/node_buffer_time_change`,formValues,true);
})
export const getnodeLeadTimeChange = createAsyncThunk('nodeLeadTimeChange/getnodeLeadTimeChange', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/node_lead_time_change`,formValues,true);
})
export const getnodeStatusChange = createAsyncThunk('nodeStatusChange/getnodeStatusChange', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/node_status_change`,formValues,true);
})
export const getrequestStatusHold = createAsyncThunk('requestStatusHold/getrequestStatusHold', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/request_hold_status`,formValues,true);
})


export const getcalenderNewDetail = createAsyncThunk('calenderNewDetail/getcalenderNewDetail', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/new_calendar_detail`,formValues);
})
export const getnodeGroup = createAsyncThunk('nodeGroup/getnodeGroup', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/node_group`,formValues);
})
export const getimportSubmission = createAsyncThunk('importSubmission/getimportSubmission', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/import_submission`,formValues,true);
})
export const getdeadline = createAsyncThunk('deadline/getdeadline', async (formValues) => {
  return handleApiRequest (process.env.REACT_APP_API_URL+`/update_transaction/deadline/`+formValues.id,formValues,true);
})

