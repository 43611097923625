import React, { useEffect, useState } from 'react'
import Table from '../../../component/Table/Table'
import { Pagination } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { gethistoryData } from '../../../store/user/actions';
import { useParams } from 'react-router-dom';
import ReactPlayer from "react-player";
import AudioPlayer from '../../ViewSubmissionDetails/component/AudioPlayer';

const HistoryTable = () => {
    const [current, setCurrent] = useState(3);
    const historyData = useSelector(state => state.historyData)
    const dispatch = useDispatch()
    const { id } = useParams();
    useEffect(() => {
      dispatch(gethistoryData(id))
    }, [])
  
    const onChange = (page) => {
        setCurrent(page);
      };

    const FakeTableData = historyData.data.map((item, i) => {
      const audioPlayer = item.audio ? <AudioPlayer audioSrc={item.voice_note} /> : <div className='diseble_play'><i className="fa fa-play-circle" aria-hidden="true"></i>
      </div>;
        return [
            <>
                <h6>{i+1}</h6>
            </>,
            <div>
                <h6>{item.node}</h6>
            </div>,
            <div className='view_im'>
              <img src={item.file}/>
            </div>,
            <div>
                <h6>{item.sampleRequest}</h6>
            </div>,
            <>
                <h6> {item.remark}</h6>
            </>,
            // <div className='audio_custom'>
            //   <ReactPlayer url={item.voice_note} controls={true} style={{width:"100%",height:"60px"}}/>
            // </div>,
            <div className='audo_io'>
            {audioPlayer}
            </div>,
           
        ]
      })
    
      const TemplateTableData = [
    
        {
          heading: "Sr.No.",
        },
        {
          heading: "Node",
        },
        {
          heading:"Image"
        },
        {
          heading: "Sample Request",
        },
        {
          heading: "Remark",
        },
        {
          heading: "Voice Note",
        },
       
      ];
  return (
    <div>
       <div className='table-bg'>
        <Table
          gridTemplateColumnsHeader="10% 20% 10% 20% 20% 20%"
          gridTemplateColumnsBody="10% 20% 10% 20% 20% 20%"
          columns={TemplateTableData}
          data={FakeTableData}
        />
        <Pagination
          current={current}
          onChange={onChange}
          total={50} />
      </div>
    </div>
  )
}

export default HistoryTable
