
import './App.css';
import {BrowserRouter, Routes, Route, Outlet, } from "react-router-dom";
import LoginPage from './pages/LoginPage/LoginPage';
import SplashPage from './pages/SplashPage/SplashPage';
import { ThemeProvider } from "styled-components";
import theme from "./theme";
import NavbarSidebarAdmin from './component/NavbarSidebarMosque/NavbarSidebarAdmin';
import Dashboard from './pages/Dashboard/Dashboard';
import "./css/header.css";
import "./css/calendar.css";
import "./css/dashboard.css";
import "./css/nodewise.css";
import "./css/collections.css";
import "./css/pending.css";
import "./css/nodebox.css";
import 'reactflow/dist/style.css';
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Collections from './pages/Collections/Collections';
import Calendar from './pages/Calendar/Calendar';
import Workload from './pages/Workload/Workload';
import MyPendingTask from './pages/MyPendingTask/MyPendingTask';
import SampleCategory from './pages/SampleCategory/SampleCategory';
import SubmissionScreen from './pages/SubmissionScreen/SubmissionScreen';
import Settings from './pages/Settings/Settings';
import AllPendingTask from './pages/AllPendingTask/AllPendingTask';
import NodeWiseTask from './pages/NodeWiseTask/NodeWiseTask';
import PublicRoute from './util/PublicRoute';
import PrivateRoute from './util/PrivateRoute';
import NodeWorkload from './pages/NodeWorkload/NodeWorkload';
import SampleNodes from './pages/SampleNodes/SampleNodes';
import Gallery from './pages/Gallery/Gallery';
import History from './pages/History/History';
import SubmissionForm from './pages/SubmissionForm/SubmissionForm';
import ViewSubmissionDetails from './pages/ViewSubmissionDetails/ViewSubmissionDetails';
import SelectNode from './pages/SelectNode/SelectNode';
import CustomBigCalendar from './pages/Calendar/component/CustomBigCalendar';
import Logs from './pages/Logs/Logs';
import RejectionRequest from './pages/RejectionRequest/RejectionRequest';

function App() {
  return (
   <BrowserRouter>
    <ThemeProvider theme={theme}>
    <Routes>
    <Route element={<PublicRoute />}>
          <Route path="/login" element={<LoginPage />} />
    </Route>
      <Route path='/splash' element={<SplashPage />}/>
        <Route element={<PrivateRoute />}>
        <Route  
       element={
         <NavbarSidebarAdmin>
            <Outlet />
           </NavbarSidebarAdmin>
        }>
          <Route path="/" element={<NodeWiseTask/>} />
          <Route path="/node-wise-task" element={<Dashboard />} />
          <Route path="/select-node" element={<SelectNode />} />
          <Route path="/node-workload/:ids" element={<NodeWorkload />} />
          <Route path="/sample-node/:id" element={<SampleNodes />} />
          <Route path="/gallery/:id" element={<Gallery />} />
          <Route path='/collections' element={<Collections/>} />
          <Route path='/calendar' element={<Calendar />} />
          <Route path='/calendar-new' element={<CustomBigCalendar/>} />
          <Route path='/logs' element={<Logs/>} />
          <Route path='/rejection-request' element={<RejectionRequest/>} />
          <Route path='/workload' element={<Workload />} />
          <Route path='/pending-task' element={<MyPendingTask />} />
          <Route path='/all-pending-task' element={<AllPendingTask />} />
          <Route path='/sample-category' element={<SampleCategory />} />
          <Route path='/submission-screen' element={<SubmissionScreen />} />
          <Route path='/view-submission-details/:id' element={<ViewSubmissionDetails />} />
          <Route path='/settings' element={<Settings/>} />
          <Route path='/history/:id' element={<History/>} />
          <Route path='/submission-form/:id' element={<SubmissionForm />} />
        </Route>
       
      </Route>
    </Routes>
    </ThemeProvider>
    <ToastContainer/>
   
   </BrowserRouter>
  );
}

export default App;
