
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Table from '../../../component/Table/Table'
import DefaultModal from '../../../component/DefaultModal/DefaultModal'
import RemarksModal from "./RemarksModal";
import { useDispatch, useSelector } from "react-redux";
import { getcollectionData } from "../../../store/user/actions";
import { DatePicker, Pagination } from 'antd';
import Loader from "../../../component/Loader";
import UserNameImage from "../../../component/UserNameImage/UserNameImage";
import CheckBox from "./CheckBox";
import CustomSwitch from "./CustomSwitch";
import ImportModal from "./ImportModal";

import DeadlineModal from "./DeadlineModal";

const DashboardTable = (props) => {
  const [show, setShow] = useState(false);
  const [currentRequest, setCurrentRequest] = useState(0);
  const [current, setCurrent] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [isModalThreeOpen, setIsModalThreeOpen] = useState(false);
  const [isModalImport, setIsModalImport] = useState(false);
  const [selectRequest, setSelectRequest] = useState([])
  const [dataUpdate, setDataUpdate] = useState(true)
  const collectionData = useSelector(state => state.collectionData)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getcollectionData(props.selectBox))
  }, [props.selectBox])

  const refreshNodeData = () => {
    dispatch(getcollectionData(props.selectBox))
  }
  const onChange = (page) => {
    setCurrent(page);
    dispatch(getcollectionData({ ...props.selectBox, page }))
  };

  const handleCheckboxChange = (id) => {
    setSelectRequest((prevCheckboxes) =>
      prevCheckboxes.map((checkbox) =>
        checkbox.id === id ? { ...checkbox, checked: !checkbox.checked } : checkbox
      )
    );
    setDataUpdate(!dataUpdate)
  };

  const handleCheckAllChange = () => {
    const allChecked = selectRequest.every((checkbox) => checkbox.checked);

    const updatedCheckboxes = selectRequest.map((checkbox) => ({
      ...checkbox,
      checked: !allChecked,
    }));
    setSelectRequest(updatedCheckboxes);
  };

  const handleDoneClick = (pageType) => {
    var idsData = selectRequest;
    if (pageType == "this_page") {
      idsData = selectRequest.filter((item2) =>
        collectionData.data.list.filter((mergedItem) => mergedItem.sampleId === item2.id).length
      );
    }
    const checkedIds = idsData
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.id);
    var payload = {};
    if (selectRequest.every((checkbox) => checkbox.checked) && pageType == "all_page") {
      payload.type = pageType;
      payload.filter = props.selectBox;
    } else {
      payload.ids = checkedIds.join(',');
    }
    localStorage.setItem("selectedPayload", JSON.stringify(payload));
    window.location.href = '/select-node';
  };

  const mergeArrays = (arr1, arr2) => {
    const mergedArray = arr1;
    const uniqueItems = arr2.filter((item2) =>
      mergedArray.every((mergedItem) => mergedItem.id !== item2.id)
    );
    return mergedArray.concat(uniqueItems);
  };
  useEffect(() => {
    const data = collectionData && collectionData.data.list.map((item, i) => {
      if (item.startDate.orignalDate == null) {
        return ({ "id": item.sampleId, "checked": false })
      } else {
        return ({ "id": 0, "checked": false })
      }
    })
    // setSelectRequest(data)
    setSelectRequest((prevCheckboxes) => mergeArrays(prevCheckboxes, data));
  }, [collectionData, dataUpdate])
  useEffect(() => {
    setTotalData(collectionData.data.total_request)
    let sno = (current - 1) * 10 + 1;

    const FakeTableData = collectionData && collectionData.data.list.map((item, i) => {
      const currentPendingNodeId = item.currentPandingNode.map((node) => node.id)
      return [
        <>
          <div className="number_size">
            <CheckBox sno={sno + i} id={item.sampleId} disabled={item.startDate.orignalDate == null} selectRequest={selectRequest} onChange={handleCheckboxChange} />
          </div>
        </>,
        <div className="samplePic">
          {/* <img src={item.samplePicture==""?"/Images/dummy.jpg":item.samplePicture} /> */}
          <img src={process.env.REACT_APP_IMG_URL + item.samplePicture} />
          <h6>{item.sampleRequestId}</h6>
        </div>,
        <>
          <h6>{item.collectionName}</h6>
        </>,
        <>
          <h6>Original<br />{item.startDate.orignalDate}</h6>
          <h6>Current <br />{item.startDate.currentDate}</h6>
          <h6>Actual <br />{item.startDate.actualDate}</h6>
        </>,
        <>
          <h6>Original<br /> {item.endDate.orignalDate}</h6><br />
          <h6>Current<br /> {item.endDate.currentDate}</h6><br />
          <h6> Actual<br /> {item.endDate.actualDate}</h6>
        </>,
        <div>
          {
            item.currentPandingNode.map((node) => {
              return <div className='currnt-nodes mt-2'>
                <UserNameImage
                  width="40px"
                  height="40px"
                  margin="0 auto"
                  name={node.user_name}
                  image={node.userImage}
                />
                <span className='msg-noti'>{node.trail}</span>
                <h6 className="mt-1">{node.nodeName}</h6>
                <h6 style={{ color: parseInt(node.delay) > 0 ? '#F00' : '#2ED47E' }}>({node.delay} Hr)</h6>
                <h6>{node.userName}</h6>
              </div>
            })
          }
        </div>,
        <>
          {
            item.nextNode.map((node) => {
              return <div className='currnt-nodes mt-2'>
                <UserNameImage
                  width="40px"
                  height="40px"
                  margin="0 auto"
                  name={node.user_name}
                  image={node.userImage}
                />
                <span className='msg-noti'>{node.trail}</span>
                <h6 className="mt-1">{node.nodeName}</h6>
                <h6>{node.userName}</h6>
              </div>
            })
          }</>,
        <>
          <h6 style={{ color: "#F00" }}>{item.rewardStrike.strike} Strike <br />Points</h6>
          <h6 style={{ color: "#00BB5A" }}>{item.rewardStrike.reward} Rewards Points</h6>
        </>,
        <>
          <img src="Images/notification.svg" />
        </>,
        <div className='pending-but'>
          <button>{item.status}</button>
        </div>,
        <>
          <h6 >{item.remark_count}</h6>
        </>,
        <div className='pending-but'>
          <h6>{item.netDelay} Day</h6>
        </div>,
        <div class="bg-image-sm">
          <div class="team-pic">
            <div className="team-member">
              <img src="Images/setting.svg" />
              <span class="tooltiptext-sm">
                <Link onClick={() => { setShow(true); setCurrentRequest(item.sampleId) }}>Add Remarks</Link>
                <Link to={"/node-workload/" + currentPendingNodeId.join(',')}>Current node Workload</Link>
                <Link to={"/sample-node/" + item.sampleId}>Sample Nodes</Link>
                <Link onClick={() => { setIsModalThreeOpen(true); setCurrentRequest(item.sampleId) }}>Add Deadline</Link>
                <span className="d-flex hold_custom border-bottom-0">
                  <Link>Hold/UnHold</Link>
                  <span className="mt-1">
                    <CustomSwitch label={'t' + item.sampleId}
                      id={item.sampleId}
                      status={item.hold == 0}
                      refreshNodeData={refreshNodeData} />
                  </span>
                </span>

              </span>
            </div>
          </div>
        </div>
      ]
    })
    setTableData(FakeTableData);
  }, [collectionData, dataUpdate, selectRequest])

  const TemplateTableData = [

    {
      heading: "Sr.No.",
    },
    {
      heading: "Sample Picture/ID",
    },
    {
      heading: "Collection Name",
    },
    {
      heading: "Start Dates",
    },
    {
      heading: "End Dates",
    },
    {
      heading: "Current Pending Nodes",
    },
    {
      heading: "Next Node",
    },
    {
      heading: "Strikes / Rewards",
    },
    {
      heading: "Notify",
    },
    {
      heading: "Current Status",
    },
    {
      heading: "Remark"
    },
    {
      heading: "Net Delay",
    },
    {
      heading: "Actions",
    },
  ];

  return (
    <div className="position-relative">
      {/* <Link to="/select-node">jjjjj</Link> */}
      <div className="d-flex justify-content-between">
        <div>
          <label className="container_checkbox"><h6>Select</h6>
            <input type="checkbox"
              checked={selectRequest.every((checkbox) => checkbox.checked)}
              onChange={handleCheckAllChange}
            />
            <span className="checkmark"></span>
          </label>
        </div>
        <div>
          <Link onClick={() => handleDoneClick("all_page")} className="blue_but" style={{ backgroundColor: "#962DFF" }}>Select all page</Link>
          <Link onClick={() => handleDoneClick("this_page")} className="green_but">Select this page</Link>
          <Link className="blue_but ml-2" style={{ marginLeft: "7px" }} onClick={() => setIsModalImport(true)}><img src="/Images/document-download.svg" /> Import Submission</Link>
        </div>
      </div>
      {
        collectionData.isLoading && <div className="loader_bg"><Loader /></div>
      }
      <div className='table-bg mt-4'>
        <Table
          gridTemplateColumnsHeader="5% 10% 7% 9% 9% 10% 10% 8% 5% 7% 6% 7% 7%"
          gridTemplateColumnsBody="5% 10% 7% 9% 9% 10% 10% 8% 5% 7% 6% 7% 7%"
          columns={TemplateTableData}
          data={tableData}
          Loader={true}
        />
        <Pagination
          current={current}
          onChange={onChange}
          total={totalData} />
      </div>
      <DefaultModal
        show={show}
        onHide={() => setShow(false)}
        className="remark-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>Add Remarks</h4>
          <div className="close-icon">
            <img src="/Images/closeBlack.svg" onClick={() => setShow(false)} />
          </div>
        </div>
        <RemarksModal
          mClose={setShow}
          requestId={currentRequest}
        />
      </DefaultModal>
      <DefaultModal
        show={isModalThreeOpen}
        onHide={() => setIsModalThreeOpen(false)}
        className="person-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>Add Deadline</h4>
          <div className="close-icon">
            <img src="/Images/closeBlack.svg" onClick={() => setIsModalThreeOpen(false)} />
          </div>
        </div>
        <DeadlineModal
          mClose={setIsModalThreeOpen}
          requestId={currentRequest}
        />
      </DefaultModal>
      <DefaultModal
        show={isModalImport}
        onHide={() => setIsModalImport(false)}
        className="remark-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>Import File</h4>
          <div className="close-icon">
            <img src="/Images/closeBlack.svg" onClick={() => setIsModalImport(false)} />
          </div>
        </div>
        <ImportModal
          mClose={setIsModalImport}
          refreshNodeData={refreshNodeData}
        //  requestId={currentRequest}
        />
      </DefaultModal>
    </div>
  )
}

export default DashboardTable