import React, { useState } from 'react'
import Accordion from 'react-bootstrap/Accordion';

const DetailsAccordion = (props) => {
  
  return (
    <div className='custom-accordion'>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header className='task-icon'>Task Details</Accordion.Header>
          <Accordion.Body>
            <div className='accordion_body'>
              {props.data.map((collection) => {
                return (<div className='child-icons'>
                  {/* <div className='accord-body-right-border'>
                  <img src="Images/border-right.svg" />
                </div> */}

                  <Accordion defaultActiveKey="">
                    <Accordion.Item eventKey="artical">
                      <Accordion.Header><div className='accordion_inside'>
                        <div className='d-flex align-items-center'>
                          <img src={collection.collectionImage} />
                          <div>
                            <small>Collection Name</small>
                            <h5>{collection.collectionName}</h5>
                          </div>
                        </div>
                        <h6>{collection.collectionTime} Hr</h6>
                      </div></Accordion.Header>
                      <Accordion.Body>

                        {collection.requests.map((request, i) => {
                          return (<div>
                               <div className='border-im'></div>
                            <Accordion defaultActiveKey="">
                              <Accordion.Item eventKey="artical-data">
                                <Accordion.Header className='ac-but'> <div className='accordion_inside'>
                                  <div className='d-flex align-items-center'>
                                    <img src={request.sampleImage} />
                                    <div>
                                      <small>Article-{i + 1}</small>
                                      <h5>{request.name}</h5>
                                    </div>
                                  </div>
                                  <h6>{request.sampleTime} Hr</h6>
                                </div></Accordion.Header>
                                <div className='border-im'></div>
                                <Accordion.Body>
                                  {request.transactions.map((transaction) => {
                                    return (<div>
                                      <div>
                                        <div className='pattern-design'>
                                        <div className={transaction.status==1 && transaction.intervalType=='before'?'green-border': transaction.status==1 && transaction.intervalType=='delay'?'red-border':transaction.status==0 ? 'yellow-border': 'gray-border'}></div>
                                          <p>{transaction.name}</p>
                                          <div className='d-flex justify-content-between'>
                                            <div className='d-flex align-items-center'>
                                              <img src={transaction.userImage} />
                                              <h5>{transaction.userName + " - " + transaction.id}</h5>
                                            </div>
                                            <h6><span className={transaction.status==1 && transaction.intervalType=='before'?'green-cir':transaction.status==1 && transaction.intervalType=='delay'?'red-cir':transaction.status==0 ? 'yellow-border':'gray-cir'}>{transaction.interval}</span>{transaction.time} Hr</h6>
                                          </div>
                                        </div>

                                      </div></div>)
                                  })}
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>

                          </div>)
                        })}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                </div>)
              })}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  )
}

export default DetailsAccordion