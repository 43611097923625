import React, { useEffect, useState } from 'react'
import { getdeadline } from '../../../store/user/actions';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useDispatch } from 'react-redux';
import { DatePicker } from 'antd';

dayjs.extend(customParseFormat);
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)
const DeadlineModal = ({ requestId, mClose }) => {

  const initialValues = {
    deadline: null, // Change to null or an empty string
  };
  const [changeDate, setChangeDate] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValue] = useState(initialValues);
  const [error, setError] = useState(false);
  const dispatch = useDispatch()
  const disabledDate = (current) => {
    // Disable dates before today
    return current && current < new Date();
  };
  const onChange = (value, date) => {
    const timestamp = date
    const tz = "Asia/Kolkata"
    const dayjsLocal = dayjs(timestamp);
    setChangeDate(dayjsLocal.tz(tz, true).format())
  }

  // validation-start
  useEffect(() => {
    if (Object.keys(formErrors).length > 0) {
    }
  }, [formErrors]);

  const validate = (values) => {
    let isValid = true;
    const errors = {};
    const tomorrow = dayjs().add(1, 'day').startOf('day'); // Tomorrow's date
    const selectedDate = dayjs(values.deadline);

    if (!values.deadline) {
      isValid = false;
      errors.deadline = "Enter Date Time";
    } else if (selectedDate.isBefore(tomorrow)) {
      isValid = false;
      errors.deadline = "The deadline must be a date after tomorrow.";
    }

    return {
      isValid,
      errors,
    };
  };
  const dateChagehandle = () => {
    setError(null);
    setLoading(true);
    let check = validate({ deadline: changeDate });
    setFormErrors(check.errors);
    if (check.isValid) {
      // Update formValues with the latest deadline value
      setFormValue((prevFormValues) => ({
        ...prevFormValues,
        deadline: changeDate,
      }));
      setLoading(false);
      dispatch(getdeadline({ deadline: changeDate, id: requestId })); // Dispatch action with the latest formValues
      mClose(false);
    }
  };

  return (
    <div>
      <div className="mt-4">
        <label className="delay_label">Select Date and Time </label>
        <DatePicker
          className="sub-input-box"
          format="YYYY-MM-DD"
          // showTime={{ defaultValue: dayjs('00:00:00') }}
          onChange={(e) => onChange('deadline', e)}
          disabledDate={disabledDate}
        />
        <span className='error-msg' style={{ color: 'red', fontSize: '12px' }}>{formErrors.deadline}</span>
        <div className='but_sec'>
          <button className='dark-but'
            type="button"
            onClick={() => dateChagehandle()}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  )
}

export default DeadlineModal
