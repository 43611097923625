import React, { useEffect, useState } from 'react'
import Searchbar from '../../../component/ListSearchBar/Searchbar';
import { getcalenderNewDetail} from '../../../store/user/actions';
import { useDispatch, useSelector } from 'react-redux';

const CalendarSidebar = ({onCloseSidebar,items,dateRange}) => {
  const [openIndex, setOpenIndex] = useState(null);
  const [value, setValue] = useState("");
  const calenderNewDetail = useSelector(state => state.calenderNewDetail)
  const dispatch = useDispatch()
  const handleItemClick = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  useEffect(()=>{
    dispatch(getcalenderNewDetail(dateRange))
  },[dateRange])
  return (
    <div className='calendar_sidebar_box'>
        <Searchbar
         value={value}
         setValue={(e) => setValue(e.target.value)}
         placeholder="Search by user and task"
         fontSize="12px"
         backgroundColor="#EBEBEB"
         borderRadius="20px"
         searchIcon
         width="100%"
         height="48px"
         position="relative"
         padding="0px 20px"
         />
      <div className='box_body'>
      <div className="close-icon close_calendr-box " onClick={onCloseSidebar}>
            <img src="/Images/closeBlack.svg" />
        </div>
      

          <div className="accordion mt-4">
      {Object.entries(calenderNewDetail.data.data).map((value, index) => 
      {
        const item = value[1]
      return (
        
        <div key={index} className={`accordion-items ${openIndex === index ? 'open' : ''}`}>
          <div
            className="accordion-headerr"
            onClick={() => handleItemClick(index)}
          >
            <div className="header-wrapper">
              <img src={process.env.REACT_APP_IMG_URL+item.user_image} alt={item.user_name} />
              <span>{item.user_name}</span>
            </div>
            <div className="arrow-icon">{openIndex === index ? <img src="/Images/arrow-up.svg" className='arrow_size'/> :  <img className='arrow_size' src="/Images/arrow-down.svg"/>}</div>
          </div>
          {openIndex === index && (
          <div className="accordion-content">
            {

              item.task.map((item,index)=>(
                <div className="header-wrapper d-flex mt-4">
                  <img src={process.env.REACT_APP_IMG_URL+item.image} alt={item.node} />
                  <div>
                  <h6>{item.node}</h6>
                  <small>{item.date}</small>
                  </div>
                </div>
              ))
            }
          </div>
          )}
        </div>
      )})}
    </div>
      </div>
    
    </div>
  )
}

export default CalendarSidebar
