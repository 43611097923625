import React, { useState } from 'react'
import RejectionTable from './component/RejectionTable'
import RejectionFilter from './component/RejectionFilter'

const RejectionRequest = () => {
    const [selectBox, setSelectBox] = useState(true);

    const filterValue = (selectValue) => {
      setSelectBox(selectValue)
    }
  return (
    <div>
         <div className="heading">
          <h2>Rejection Request</h2>
        </div>
        <RejectionFilter 
        filterValue={filterValue}
        />
      <RejectionTable 
      selectBox={selectBox}
      />
    </div>
  )
}

export default RejectionRequest
