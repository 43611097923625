import React, { useEffect, useState } from 'react'
import Searchbar from '../../../component/ListSearchBar/Searchbar'
import UserNameImage from '../../../component/UserNameImage/UserNameImage';
import { useDispatch, useSelector } from 'react-redux';
import { getprevReSubmission, getuserListByDesignation } from '../../../store/user/actions';
import { resetprevReSubmissionState } from '../../../store/user/userSlice';

const SelectManger = ({ id, managerDesignation, setShowRejection, prevId, refreshNodeData }) => {
  const [value, setValue] = useState("");
  const [userList, setUserList] = useState([]);
  const userListByDesignation = useSelector(state => state.userListByDesignation)
  const prevReSubmission = useSelector(state => state.prevReSubmission)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getuserListByDesignation({ designation_id: managerDesignation }))
  }, [])
  useEffect(() => {
    setUserList(userListByDesignation.data.list)
  }, [userListByDesignation])

  const assined = (userId) => {
    dispatch(getprevReSubmission({ id, submission_sample_manager_id: userId, previous_id: prevId }))
    //  if(prevReSubmission.isLoading==false){
    //  viewPersonModal(false)
    //  }
  }
  useEffect(() => {
    if (prevReSubmission.isSuccess) {
      dispatch(resetprevReSubmissionState())
      setShowRejection(false);
      refreshNodeData();
    }
  }, [prevReSubmission])
  return (
    <div>
      <div className='mt-4'>
        <Searchbar
          value={value}
          setValue={(e) => setValue(e.target.value)}
          placeholder="Search"
          fontSize="12px"
          backgroundColor="#F5F5F5"
          borderRadius="4px"
          searchIcon
          width="354px"
          height="48px"
          position="relative"
          padding="0px 20px"

        />
        <div className="position-relative">

        </div>
        <div className='persion-scroll'>
          {
            userList.map((item) => (
              <div className='persion-flex'>
                <div className='d-flex'>
                  <UserNameImage
                    width="42px"
                    height="42px"
                    margin="0 auto"
                    name={item.firstname + ' ' + item.lastname}
                    image={item.profile_image_thumbnail}
                  />
                  {/* <img src={item.profile_image_thumbnail} /> */}
                  <div>
                    <h6>{item.firstname + ' ' + item.lastname}</h6>
                    <p>Current Workload - {item.workload}</p>
                  </div>
                </div>
                <button className='assigne' onClick={() => assined(item.value)}>Select</button>

              </div>
            ))
          }

        </div>

      </div>
    </div>
  )
}

export default SelectManger
