import React, { useEffect, useState } from 'react'

const FormTable = ({group_fields, handleChangeInput,index}) => {
  
  const [inputFields, setInputFields] = useState([]);
  const [defaultValue, setDefaultValue] = useState();
  const [inputFieldValue, setInputFieldValue] = useState([]);

  useEffect(() => {
    const ids = group_fields.map((item) => item.id);
    const initialValues = {};
    
    for (let index = 0; index < ids.length; index++) {
      initialValues[ids[index]] = '';
    }

    setInputFields([initialValues]);
    setInputFieldValue([initialValues]);
    setDefaultValue(initialValues);
  }, [group_fields]);

  useEffect(()=>{
    handleChangeInput(index,inputFieldValue);
  },[inputFieldValue])

  const handleAddField = () => {
    setInputFields((prevInputFields) => [...prevInputFields, { ...defaultValue }]);
    setInputFieldValue((prevInputFields) => [...prevInputFields, { ...defaultValue }]);
  };

  const handleChange = (i, inputid, valuedata) => {
    setInputFieldValue((prevInputFields) => {
      const newFormValues = [...prevInputFields];
      newFormValues[i] = { ...newFormValues[i], [inputid]: valuedata };
      return newFormValues;
    });
  };

  const handleRemoveField = (id)=> {
    setInputFields((prevInputFields) =>
      prevInputFields.filter((field, index) => index !== id)
    );
    setInputFieldValue((prevInputFields) =>
      prevInputFields.filter((field, index) => index !== id)
    );
  };
  return (
    <div className='custom_table_row'>
      <table className="table">
        <thead className="table-light">
        <tr>
            <th>
            <div className="table_top" onClick={handleAddField}>
                <i className="fa fa-plus-circle" aria-hidden="true"></i>
                </div>
            </th>
            {
                group_fields.map((item,i) =>(
                    <th scope="col">
                    <div className="table_top">
                     {item.label}   
                    </div>
                </th>
                ))
            }
           
        </tr>
        </thead>
        <tbody>
        {inputFields.map((field,i) => (

        <tr>
     <>         <td>
             <div className="table_top" onClick={() => handleRemoveField(field.id)}>
                <i className="fa fa-minus-circle" aria-hidden="true"></i>
                </div>
          </td>
          {group_fields.map((input) =>(
            <td>
                <label>{input.label}</label>
                          {input.field_type=="input"?
                          <input
                            type={input.field_type}
                            className="sub-input-box"
                            onChange={(e) => handleChange(i,input.id, e.target.value)}
                          />:<select
                          className="sub-input-box"
                          onChange={(e) => handleChange(i,input.id, e.target.value)}
                        >
                          {
                            input.data.map(listOpton =>(
                            <option value={listOpton.id}>{listOpton.name}</option>
                            ))
                          }
                          
                        </select>}
            </td>
           ))}
        </>
      
        {/* {inputFields.map((field) => (
          <div key={field.id}>
            <input
              type="text"
              value={field.value}
              onChange={(e) => handleChange(field.id, e.target.value)}
            />
            <button type="button" onClick={() => handleRemoveField(field.id)}>
              Remove
            </button>
          </div>
        ))} */}
       </tr>
    ))}
        </tbody>
        </table>
    </div>
  )
}

export default FormTable