
import React, { useEffect, useState } from 'react'
import Table from '../../../component/Table/Table';
import AudioPlayer from '../../ViewSubmissionDetails/component/AudioPlayer';
import { useDispatch, useSelector } from 'react-redux';
import { getcurrentNodeSubmission, getresubmissionRequiestStatus } from '../../../store/user/actions';
import { Link, useParams } from 'react-router-dom';
import UserNameImage from '../../../component/UserNameImage/UserNameImage';

const ViewSubmissonModal = ({ id, trainId, status, setViewNode, refreshdata }) => {
  const [tableData, setTableData] = useState([]);
  const currentNodeSubmission = useSelector((state) => state.currentNodeSubmission);
  const resubmissionRequiestStatus = useSelector((state) => state.resubmissionRequiestStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getcurrentNodeSubmission(trainId));
  }, []);

  const statusUpdate = (request_status) => {
    dispatch(getresubmissionRequiestStatus({ request_id: id, status: request_status }))
  }

  useEffect(() => {
    if (resubmissionRequiestStatus.isSuccess) {
      setViewNode(false)
      refreshdata();
    }
  }, [resubmissionRequiestStatus])

  useEffect(() => {

    const FakeTableData = currentNodeSubmission && currentNodeSubmission.data.map((item, i) => {

      return [
        <div className='currnt-nodes'>
          <UserNameImage width='40px' height='40px' margin='0 auto' name={item.user_name} image={item.user_image} />
          <h6>{item.node_name}</h6>
          <h6>{item.user_name}</h6>
        </div>,
        <h6>{item.submit_datetime}</h6>,
        <div>
          <h6> {item.delay}Days</h6>
        </div>,
        <div className='view_im'>
          <img src={process.env.REACT_APP_IMG_URL+item.file} alt='im' />
        </div>,
        <div className='audo_io'>
          <AudioPlayer audioSrc={process.env.REACT_APP_IMG_URL+item.audio} />
        </div>,
        <h6>{item.remark}</h6>,
        <>
          {status == 0 ? <>
            <div className='view_a_green'>
              <Link onClick={() => statusUpdate("approve")}>
                Rejection Accepted
              </Link>
            </div>
            <div className='view_a_red'>
              <Link onClick={() => statusUpdate("rejected")}>
                Rejection Declined </Link>
            </div></> : ""}
        </>
        ,


      ]
    })
    setTableData(FakeTableData);
  }, [currentNodeSubmission])

  const TemplateTableData = [
    { heading: 'Username' },
    { heading: 'Submit Date' },
    { heading: 'Delay Time' },
    { heading: 'Image' },
    { heading: 'Voice Note' },
    { heading: 'Remark' },
    { heading: 'Action' },
  ];
  return (
    <div>
      <div className=''>
        <Table
          gridTemplateColumnsHeader='15% 10% 10% 20% 10% 18% 17%'
          gridTemplateColumnsBody='15% 10% 10% 20% 10% 18% 17%'
          columns={TemplateTableData}
          data={tableData}
        />
        {/* <Pagination
          current={current}
          onChange={onChange}
          total={totalData} /> */}
      </div>
    </div>
  )
}

export default ViewSubmissonModal

