import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './login.css'
import { apiCall, setUserSession } from '../../util/Common';
import { Helmet } from 'react-helmet';
const LoginPage = () => {

  const initialValues = {
    username: "",
    password: "",
  };
  const [formValues, setFormValue] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useNavigate();

  const handleLogin = async () => {
    setError(null);
    setLoading(true);
    let check = validate(formValues);

    if (check.isValid) {
      const response = await apiCall("/login", "POST", formValues);
      setLoading(false);
      if (response.status == 1) {
        setUserSession(response.data.token, response.data)
        history("/")
      } else if (response.status == 2) {
        history("/login")
      } else {
        setError(response.message)
        setFormErrors(response.data)
      }
    } else {
      setFormErrors(check.errors);
    }
  };

  // validation-start
  useEffect(() => {
    if (Object.keys(formErrors).length > 0) {
    }
  }, [formErrors]);

  const validate = (values) => {
    let isValid = true;
    const errors = {};
    if (!values.username) {
      isValid = false;
      errors.username = "name is required";
    }
    if (!values.password) {
      isValid = false;
      errors.password = "password is required";
    }
    return {
      isValid,
      errors,
    };
  };
  const handleChange = (keyName, keyValue) => {
    let data = { ...formValues };
    data[keyName] = keyValue;
    setFormValue(data);
  };
  return (
    <div className='loginBg'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login | Sample Dashboard</title>
      </Helmet>
      <div className='login-form'>
        <div className='logo-im'><img src="Images/logo.svg" /></div>
        <h1>Welcome!</h1>
        <small>Please enter your log in details below......</small>
        <div>{error}</div>
        <div className="mt-4">
          <label>Username </label>
          <input
            value={formValues.username}
            onChange={(e) => handleChange("username", e.target.value)}
            type="text"
            placeholder="Username"
            className="input-box"
          />
          <span>{formErrors.username}</span>
        </div>
        <div className="mt-4">
          <label> Password </label>
          <input
            value={formValues.password}
            onChange={(e) => handleChange("password", e.target.value)}
            type="password"
            placeholder="Enter Password"
            className="input-box"
          />
          <span>{formErrors.password}</span>
        </div>
        <input
          className="login-but mt-4"
          type="button"
          value={loading ? 'Loading...' : 'Login'}
          onClick={handleLogin}
          disabled={loading} />
      </div>
    </div>
  )
}

export default LoginPage
