import React, { useState } from 'react'
import DonutChart from './component/DonutChart'
import CollectionTable from './component/CollectionTable'
import { Helmet } from 'react-helmet';

const Collections = () => {
  const [show, setShow] = useState(true);


  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Collections | Sample Dashboard</title>
      </Helmet>
      {/* {show && ( */}
        <div>
          <DonutChart />
          <CollectionTable/>
        </div>
      {/* )}
      {!show && <GalleryScreen Open={Open} />} */}

    </div>
  )
}

export default Collections
