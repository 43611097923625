import { Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useDispatch, useSelector } from 'react-redux';
import { getassignNodeData, getnodeFormData } from '../../store/user/actions';
import { useParams } from 'react-router-dom';
import FormTable from './component/FormTable';


const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};
const { Option } = Select;

const SubmissionForm = () => {
  const [formValues, setFormValue] = useState({});
  const [files, setFiles] = useState([]);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [isDropzoneVisible, setDropzoneVisibility] = useState(true);
  const { id } = useParams();
  const assignNodeData = useSelector(state => state.assignNodeData)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getassignNodeData(id))
  }, [])


  const [selectData, setSelectData] = useState([
    { id: 1, label: 'Option 1', value: 'option1' },
    { id: 2, label: 'Option 2', value: 'option2' },
    { id: 3, label: 'Option 3', value: 'option3' },
  ]);

  const handleSelectChange = (id, value) => {
    // Handle the change in select value here if needed

  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': []
    },
    multiple: true,
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
      if (acceptedFiles.length > 0) {
        // Assuming you only want to display the first uploaded image
        setUploadedImage(acceptedFiles[0]);
        setDropzoneVisibility(false); // Hide the dropzone
      }
    }
  });
  const showDropzone = () => {
    setUploadedImage(null); // Clear the uploaded image
    setDropzoneVisibility(true); // Show the dropzone again
  };
  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />
      </div>
    </div>
  ));
  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    setFiles([...selectedFiles]);
  };

  const optionsSelect = [
    {
      value: "Alka",
      lable: "Alka"
    },
    {
      value: "AT",
      lable: "AT"
    },
    {
      value: "RT",
      lable: "RT"
    },
  ]
  const handleChange = (name, value) => {
    let data = { ...formValues };
    data[name] = value;
    setFormValue(data);
  };
  const groupHandleChange = (index, value) => {
    let data = { ...formValues };
    if (!data.group) {
      data.group = {}
    }
    data.group[index] = value;
    setFormValue(data);
  };

  const formSubmit = () => {
    dispatch(getnodeFormData({ sample_category_node_transactions_id: id, fields_data: JSON.stringify(formValues) }))
  }

  return (
    <div className='submission-form'>
      <div className='row'>
        <div className='col-lg-12'>
          <div className='row'>
            {
              assignNodeData.data.sampleNodeValues.outer_fields.map(input => (
                <div className='col-lg-4 col-md-4 mt-4'>
                  <label>{input.node_field.label}:</label>
                  {input.data.value}
                </div>
              ))
            }

            {
              assignNodeData.data.sampleNodeValues.group_fields.map(input => (
                <div className='col-lg-4 col-md-4 mt-4'>
                  <label>{input.node_field.label}:</label>
                  <div>
                    {input.data.map(value => (<div>{value.value}</div>))}
                  </div>
                </div>
              ))
            }
          </div>
        </div>
        <div className='row'>

          {assignNodeData.data.sampleNodeFields.outer_fields.map(input => (
            <div className='col-lg-4 mt-4'>
              <label>{input.label}</label>
              {input.field_type == "input" ?
                <input
                  type={input.field_type}
                  className="sub-input-box"
                  onChange={(e) => handleChange(input.id, e.target.value)}
                /> : <select
                  className="sub-input-box"
                  onChange={(e) => handleChange(input.id, e.target.value)}
                >
                  {
                    input.data.map(listOpton => (
                      <option value={listOpton.id}>{listOpton.name}</option>
                    ))
                  }

                </select>}
            </div>
          ))}
        </div>

        {/* <div className='col-lg-4 mt-4'>
                    <label> File Upload </label>
                    <div {...getRootProps({ className: 'upload-file-sub' })}>
                            <input {...getInputProps()} multiple onChange={handleFileChange}/>
                            {
                            files.length==0 ? 
                            <div>
                                <i className="fa fa-upload" aria-hidden="true"></i>
                            </div>
                        :<></>}
                            <aside style={thumbsContainer}>
                            {thumbs}
                            </aside>
                        </div>
                    </div> */}

      </div>
      {assignNodeData.data.sampleNodeFields.group_fields.map((value, i) => (
        <FormTable
          group_fields={value}
          handleChangeInput={groupHandleChange}
          index={i}
        />
      ))}


      <div className='mt-4 mb-4'> <button type='button' className="form_but" onClick={() => formSubmit()} >Submit</button></div>

    </div>
  )
}

export default SubmissionForm