import React, { useEffect, useState } from 'react'
import { Select } from 'antd';
import { getfilterData } from '../../../store/user/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const CalendarSelect = ({filterValue}) => {
  const filterData = useSelector(state => state.filterData.data)
  const dispatch = useDispatch()

  const initialValues = {
    userId: "",
    sampleRequestNo: "",
    sample_collection_id: "",
    sampleCategoryId: "",
    currentPendingNodeId: "",
    nextNodeId: "",
    previousNodeId: "",
    actualDateTo:"",
    trail:"",

  };
  const [formValues, setFormValue] = useState(initialValues);
  useEffect(() => {
    filterValue(formValues)
  }, [formValues])

  useEffect(() => {
    dispatch(getfilterData(formValues))
  }, [])


  const userOption = Object.entries(filterData.user).map(([value, label]) => ({
    value: parseInt(value, 10),
    label,
  }));
  const sampleRequestOption = Object.entries(filterData.sample_request).map(([value, label]) => ({
    value: parseInt(value, 10),
    label,
  }));
  const sampleCollectionOption = Object.entries(filterData.sample_collection).map(([value, label]) => ({
    value: parseInt(value, 10),
    label,
  }));
  const sampleCategoriesOption = Object.entries(filterData.sample_categories).map(([value, label]) => ({
    value: parseInt(value, 10),
    label,
  }));
  const sampleNodesOption = Object.entries(filterData.sample_nodes).map(([value, label]) => ({
    value: parseInt(value, 10),
    label,
  }));
  const sampleTrailOption = Object.entries(filterData.sample_trail).map(([value, label]) => ({
    value,
    label,
  }));

  const handleChange = (name, value) => {
    let data = { ...formValues };
    data[name] = value;
    setFormValue(data);
  };



  return (
    <div>
      <div className='row mt-4'>
      <div className='col-md-3 col-lg-3 col-12 mb-2'>
      <label className='select-label'>Username</label>
        <Select
         showSearch
         placeholder="Username / Id"
         value={formValues.userId}
         onChange={(e) => handleChange("userId", e)}
         options={userOption}
          optionFilterProp="children"
          filterOption={(input, userOption) => ((userOption?.label).toLowerCase() ?? '').includes((input).toLowerCase())}
          filterSort={(userOption) =>
            (userOption?.label ?? '').toLowerCase().localeCompare((userOption?.label ?? '').toLowerCase())
          }
        />
      </div>
      <div className='col-md-3 col-lg-3 col-12 mb-2'>
      <label className='select-label'>Sample Request Number</label>
        <Select
          defaultValue="Sample Request Number"
          onChange={(e) => handleChange("sampleRequestNo", e)}
          value={formValues.sampleRequestNo}
          options={sampleRequestOption}
          showSearch
          optionFilterProp="children"
          filterOption={(input, sampleRequestOption) => ((sampleRequestOption?.label).toLowerCase() ?? '').includes((input).toLowerCase())}
          filterSort={(sampleRequestOption) =>
            (sampleRequestOption?.label ?? '').toLowerCase().localeCompare((sampleRequestOption?.label ?? '').toLowerCase())
          }

        />
      </div>
      <div className='col-md-3 col-lg-3 col-12 mb-2'>
      <label className='select-label'>Collection Name</label>
        <Select
          defaultValue="Collection Name"
          onChange={(e) => handleChange("sample_collection_id", e)}
          value={formValues.sample_collection_id}
          options={sampleCollectionOption}
          showSearch
          optionFilterProp="children"
          filterOption={(input, sampleCollectionOption) => ((sampleCollectionOption?.label).toLowerCase() ?? '').includes((input).toLowerCase())}
          filterSort={(sampleCollectionOption) =>
            (sampleCollectionOption?.label ?? '').toLowerCase().localeCompare((sampleCollectionOption?.label ?? '').toLowerCase())
          }
        />
      </div>
      <div className='col-md-3 col-lg-3 col-12 mb-2'>
      <label className='select-label'>Current Pending Node</label>
        <Select
          defaultValue="Current Pending Node"
          onChange={(e) => handleChange("currentPendingNodeId", e)}
          value={formValues.currentPendingNodeId}
          options={sampleNodesOption}
          showSearch
          optionFilterProp="children"
          filterOption={(input, sampleNodesOption) => ((sampleNodesOption?.label).toLowerCase() ?? '').includes((input).toLowerCase())}
          filterSort={(sampleNodesOption) =>
            (sampleNodesOption?.label ?? '').toLowerCase().localeCompare((sampleNodesOption?.label ?? '').toLowerCase())
          }
        />
      </div>
      <div className='col-md-3 col-lg-3 col-12 mb-2'>
      <label className='select-label'>Next Node</label>
        <Select
          defaultValue="Next Node"
          onChange={(e) => handleChange("nextNodeId", e)}
          value={formValues.nextNodeId}
          options={sampleNodesOption}
          showSearch
          optionFilterProp="children"
          filterOption={(input, sampleNodesOption) => ((sampleNodesOption?.label).toLowerCase() ?? '').includes((input).toLowerCase())}
          filterSort={(sampleNodesOption) =>
            (sampleNodesOption?.label ?? '').toLowerCase().localeCompare((sampleNodesOption?.label ?? '').toLowerCase())
          }
        />
      </div>
      <div className='col-md-3 col-lg-3 col-12 mb-2'>
      <label className='select-label'>Previous Node</label>
        <Select
          defaultValue="Previous Node"
          onChange={(e) => handleChange("previousNodeId", e)}
          value={formValues.previousNodeId}
          options={sampleNodesOption}
          showSearch
          optionFilterProp="children"
          filterOption={(input, sampleNodesOption) => ((sampleNodesOption?.label).toLowerCase() ?? '').includes((input).toLowerCase())}
          filterSort={(sampleNodesOption) =>
            (sampleNodesOption?.label ?? '').toLowerCase().localeCompare((sampleNodesOption?.label ?? '').toLowerCase())
          }
        />
      </div>
      <div className='col-md-3 col-lg-3 col-12 mb-2'>
      <label className='select-label'>Trail</label>
        <Select
          defaultValue="Trial"
          onChange={(e) => handleChange("trail", e)}
          value={formValues.trail}
          options={sampleTrailOption}
          showSearch
          optionFilterProp="children"
          filterOption={(input, sampleTrailOption) => ((sampleTrailOption?.label).toLowerCase() ?? '').includes((input).toLowerCase())}
          filterSort={(sampleTrailOption) =>
            (sampleTrailOption?.label ?? '').toLowerCase().localeCompare((sampleTrailOption?.label ?? '').toLowerCase())
          }
        />
      </div>
      <div className='col-md-3 col-lg-3 col-12 mb-2'>
     <div className='clearDatas'>
       <Link onClick={()=> setFormValue(initialValues)} className='clear_data'>Clear Data</Link>
       </div>
      </div>

      </div>
    </div>
  )
}

export default CalendarSelect
