import React, { useEffect, useState } from 'react'
import { getmonthPending } from '../../../store/user/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Icon from '../../../component/Icon';
const CalendarHeader = (props) => {
  const [currentDate, setCurrentDate] = useState(new Date().toDateString());
  const [defaultView, setDefaultView] = useState('month')
  const monthPending = useSelector(state => state.monthPending)
  const [month, setMonth] = useState([]);
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const dispatch = useDispatch()
  useEffect(() => {
    //   const currentDateTime = new Date(currentDate);
    //   const endDateTime = new Date(currentDate);
    //   const dateOfMonth = []
    //   endDateTime.setMonth(endDateTime.getMonth()+5) 
    //   while (currentDateTime <= endDateTime) {
    //       // var formattedDate = ('0' + (currentDateTime.getMonth() + 1)).slice(-2) + '-' + 
    //       // currentDateTime.getFullYear();
    //       dateOfMonth.push(currentDateTime.toDateString());
    //       currentDateTime.setMonth(currentDateTime.getMonth() + 1);
    //     }
    //     setMonth(dateOfMonth)
   const selectBoxInput = props.selectBox
    dispatch(getmonthPending({ currentDate,...selectBoxInput}))
  }, [currentDate])
  useEffect(() => {
    setCurrentDate(props.currentDate)
  }, [props.currentDate])
  const back = () => {
    const currentDateTime = new Date(currentDate);
    currentDateTime.setMonth(currentDateTime.getMonth() - 1);
    setCurrentDate(currentDateTime.toDateString())
  }
  const next = () => {
    const currentDateTime = new Date(currentDate);
    currentDateTime.setMonth(currentDateTime.getMonth() + 1);
    setCurrentDate(currentDateTime.toDateString())
  }
  const monthList = (month) => {
    props.setDefaultDate(new Date(month).toDateString());
  };
  return (
    <div className='container'>
      <div className='month_black_box'>
        <div onClick={back} className='prev_icn'><img src="/Images/prev_icn.svg" /></div>
        <div className='row'>
          {monthPending.data.map((item) => {
            return (
              <div className='col-lg-2 col-md-2' onClick={() => monthList(new Date(item.month).toString())}>
                <div className={new Date(item.month).getMonth() + " " + new Date(item.month).getFullYear() == new Date(currentDate).getMonth() + " " + new Date(currentDate).getFullYear() ? 'month-box active' : "month-box"}>
                  <h6>{monthNames[new Date(item.month).getMonth()] + " " + new Date(item.month).getFullYear()}</h6>
                  {/* <Link className='pending-Link'>
                  0 Pending
                </Link> */}
                  <div className='header_icon'>
                    <Icon
                      iconName="tshirt"
                      width="22px"
                      height="22px"
                      color={item.active ? "#fff" : "#33DABD"}
                    >
                    </Icon>
                    <h6 style={{ color: "#33DABD" }}>{item.totalRequest}</h6>
                    <div className='boder_right'> </div>
                    <Icon
                      iconName="request"
                      width="22px"
                      height="22px"
                      color={item.active ? "#fff" : "#F23030"}
                    >
                    </Icon>
                    <h6 style={{ color: "#F23030" }}>{item.totalPanding}</h6>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <div onClick={next} className='next_icn'><img src="/Images/next_icn.svg" /></div>
      </div>
    </div>
  )
}

export default CalendarHeader
