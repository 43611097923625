// import React from 'react'

// const AudioRemark = () => {
//   return (
//     <div>
      
//     </div>
//   )
// }

// export default AudioRemark


import React, { useEffect, useRef, useState, useCallback } from "react";
import useRecorder from "./UseRecorder";

const TimerController = (props) => {
  const [renderedStreamDuration, setRenderedStreamDuration] = useState(
      "00:00:00"
    ),
    streamDuration = useRef(0),
    previousTime = useRef(0),
    requestAnimationFrameId = useRef(null),
    [isStartTimer, setIsStartTimer] = useState(false),
    [isStopTimer, setIsStopTimer] = useState(false),
    [isPauseTimer, setIsPauseTimer] = useState(false),
    [isResumeTimer, setIsResumeTimer] = useState(false),
    isStartBtnDisabled = isPauseTimer || isResumeTimer || isStartTimer,
    isStopBtnDisabled = !(isPauseTimer || isResumeTimer || isStartTimer);

  const updateTimer = useCallback(() => {
    let now = performance.now();
    let dt = now - previousTime.current;

    if (dt >= 1000) {
      streamDuration.current = streamDuration.current + Math.round(dt / 1000);
      const formattedStreamDuration = new Date(streamDuration.current * 1000)
        .toISOString()
        .substr(11, 8);
      setRenderedStreamDuration(formattedStreamDuration);
      previousTime.current = now;
    }
    requestAnimationFrameId.current = requestAnimationFrame(updateTimer);
  }, []);

  const startTimer = useCallback(() => {
    previousTime.current = performance.now();
    requestAnimationFrameId.current = requestAnimationFrame(updateTimer);
  }, [updateTimer]);



  useEffect(() => {
    if (props.record === true) {
      startHandler();
    } else {
      stopHandler();
    }
    if (isStartTimer && !isStopTimer) {
      startTimer();
    }
    if (isStopTimer && !isStartTimer) {
      streamDuration.current = 0;
      cancelAnimationFrame(requestAnimationFrameId.current);
      setRenderedStreamDuration("00:00:00");
    }
  }, [isStartTimer, isStopTimer, startTimer, props.record]);

  const startHandler = () => {
    setIsStartTimer(true);
    setIsStopTimer(false);
  };

  const stopHandler = () => {
    setIsStopTimer(true);
    setIsStartTimer(false);
    setIsPauseTimer(false);
    setIsResumeTimer(false);
  };

  return (
    <div className="timer-controller-wrapper">{renderedStreamDuration}s</div>
  );
};

function AudioRemark(props) {
  const [state, setState] = useState("");
  const [audios, setAudios] = useState([]);
  const [record, setRecord] = useState(false);
  const [play, setPlay] = useState(false);
  let [audioURL, isRecording, startRecording, stopRecording] = useRecorder();

  useEffect(() => {
    
    if (audioURL.length <= 0) {
      setAudios([]);
    } else {
      setAudios([audioURL]);
    }
  }, [audioURL]);
  useEffect(() =>{
    props.audioValue(audios)
  },[audios])

  function Buttonstart() {
    setState("rgba(55, 185, 156, 0.36)");
    setRecord(true);
    startRecording();
  }
  async function Buttonstop() {
    if (isRecording === true) {
      await stopRecording();
      setState("");
      setRecord(false);
    } 
  }
  function Deletedata(id) {
    const data = audios.filter((e, index) => {
      return index !== id;
    });
    setAudios(data);
  }
  function stateaudio(e, index) {
    if (e.target.classList.contains("fa-play")) {
      e.target.classList.remove("fa-play");
      e.target.classList.add("fa-pause");
    } else if (e.target.classList.contains("fa-pause")) {
      e.target.classList.remove("fa-pause");
      e.target.classList.add("fa-play");
    }

    var myAudio = document.getElementById(`audioId${index}`);
    return myAudio.paused ? myAudio.play() : myAudio.pause();
  }
  return (
    <div>
      {audios.map((res, index) =>
        
              <div className="mic-flex"
              >
                <div>
                  <div hidden>
                    <audio
                      id={`audioId${index}`}
                      onEnded={() => {
                        if (
                          document
                            .getElementById(`playAudio${index}`)
                            .classList.contains("fa-pause")
                        ) {
                          document
                            .getElementById(`playAudio${index}`)
                            .classList.add("fa-play");
                          document
                            .getElementById(`playAudio${index}`)
                            .classList.remove("fa-pause");
                        }
                      }}
                      src={res.data}
                      controls
                      type="audio/mp3"
                    />
                  </div>
                  <div>
                    <button type="button" className={"btn-recording"}>
                      <i
                        id={`playAudio${index}`}
                        onClick={(e) => stateaudio(e, index)}
                        className={"fa fa-play"}
                      />
                    </button>
                  </div>
                </div>

                <div className="delete-but" onClick={() => Deletedata(index)}> <img src="/Images/delete.svg" /></div>
              </div>
            
          
      )}
      <div className="play-icon" >
        <div
          style={{
            background: state === "" ? "" : state,
          }}
          onClick={() => {
            Buttonstart();
            setTimeout(() => Buttonstop(), 500);
          }}
          onTouchStart={() => setTimeout(() => Buttonstart(), 500)}
          onMouseDown={() => setTimeout(() => Buttonstart(), 500)}
          onBlur={() => setTimeout(() => Buttonstop(), 500)}
          onMouseUp={() => setTimeout(() => Buttonstop(), 500)}
        >
          {record === true ? (
            <>
              <span className="timer-set">
                <TimerController record={record} />
              </span>
            </>
          ) : (
            <img src="/Images/mic.svg" />
          )}
        </div>
      
      </div>
    </div>
  );
}
export default AudioRemark;


