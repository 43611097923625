import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Table from '../../../component/Table/Table'
import DefaultModal from '../../../component/DefaultModal/DefaultModal'
import { useDispatch, useSelector } from "react-redux";
import { getallpendingData, getprevReSubmission  } from "../../../store/user/actions";
import { Pagination } from "antd";
import Loader from "../../../component/Loader";
import SubmissionModal from "../../MyPendingTask/components/SubmissionModal";
import RemarksModal from "../../Dashboard/component/RemarksModal";
import UserNameImage from "../../../component/UserNameImage/UserNameImage";
import { resetprevReSubmissionState } from "../../../store/user/userSlice";
// import SubmissionModal from "./SubmissionModal";
// import RemarksModal from "./RemarksModal";

const AllPendingTable = (props) => {
  const [show, setShow] = useState(false);
  const [current, setCurrent] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [showsubmission, setShowsubmission] = useState(false);
  const [currentRequest, setCurrentRequest] = useState(0);
  const [transtionId, setTranstionId] = useState(0);
  const [tableData, setTableData] = useState([])
  const allpendingData = useSelector(state => state.allpendingData)
  const prevReSubmission = useSelector(state => state.prevReSubmission)
  const dispatch = useDispatch()
  const { id } = useParams();

  useEffect(() => {
    dispatch(getallpendingData(props.selectBox))
  }, [props.selectBox])

  const onChange = (page) => {
    setCurrent(page);
    dispatch(getallpendingData({...props.selectBox,page}))
  };
  const preNodeSubmission = (id)=>{
    dispatch(getprevReSubmission({id}))
  }
  const refreshNodeData =()=>{
    dispatch(getallpendingData(props.selectBox))
  }
  useEffect(()=>{
    if(prevReSubmission.isSuccess){
      dispatch(resetprevReSubmissionState())
      refreshNodeData()
    }
  },[prevReSubmission])

  useEffect(()=>{
    setTotalData(allpendingData.data.totalTask)
    let sno = (current-1)*10+1;
    const FakeTableData = allpendingData && allpendingData.data.task.map((item, i) => {
      return [
        <>
          <h6>{sno+i} </h6>
        </>,
        <div>
          <h6>{item.SampleId}</h6>
        </div>,

        <div className="pending-table-img">
          <img src={item.image==""?"/Images/dummy.jpg":process.env.REACT_APP_IMG_URL+item.image} />
          <h6>{item.taskName}</h6>
        </div>,
        <>
          <h6>{item.collectionName}</h6>
        </>,
        <>
          <h6>{item.nextTask}</h6>
        </>,
        <div className="all-pending-employee">
          <UserNameImage
            width="50px"
            height="32px"
            margin="0 auto"
            name={item.userName}
            image={item.userImage}
          />
          {/* <img src={item.userImage} /> */}
          <h6>{item.userName}</h6>
        </div>,
        <>
          <h6>{item.planDate}</h6>
        </>,
        <>
          <h6>{item.delay} Days</h6>
        </>,
        <div>
          <h6> {item.currentPlanDate} </h6>
        </div>,
        <div className="text-center mx-auto">
          <img src="/Images/notification.svg" />
        </div>,
        <div>
          <h6>{item.submission}</h6>
        </div>,

        <div class="bg-image-sm">
          <div class="team-pic">
            <div className="team-member">
              <img src="/Images/setting.svg" />
              <span class="tooltiptext-sm">
              <Link onClick={() => {setShow(true);setCurrentRequest(item.sampleRequestId)}}>Add Remarks</Link>
                <Link to={"/node-workload/"+item.id}>Current node Workload</Link>
                {/* <Link onClick={() => preNodeSubmission(item.id)}>Pre. Node Submission</Link> */}
                <Link onClick={()=> {setShowsubmission(true);setTranstionId(item.id)}}>Add Submission Current Node</Link>
              </span>
            </div>
          </div>
        </div>
      ]
    })
    setTableData(FakeTableData)
  },[allpendingData])

  const TemplateTableData = [

    {heading: "Sr.No."},
    {heading: "Id"},
    {heading: "Tasks"},
    {heading: "Collections Name"},
    {heading: "Next Task"},
    {heading: "Responsible Person"},
    {heading: "Planed Date"},
    {heading: "Delay"},
    {heading: "Current Planed Date"},
    {heading: "Notify"},
    {heading: "Submission"},
    {heading: "Actions"},
  ];
  return (
    <div>
      <div className='padding-section'>
        <div className='list_flex'>
          {
            allpendingData && allpendingData.data.userTask.map((item, i) => (
              <div className='list_all_user'>
                <div className='fllter-con all-pending-list' style={{ width: "90px" }}>
                <UserNameImage 
                        name={item.name}
                        image={item.image}
                        width="64px"
                        height="64px"
                        margin="0 auto"
                        title={item.name}
                        />
                {/* <img src={item.image==""?"/Images/dummy.jpg":item.image} /> */}
                  <h6 className='pt-2'>{item.tasks} Task Pending</h6> 
                  <h6 className="pt-1">{item.hours} Hours</h6>
                </div>
              </div>
            ))
          }
        </div>
      </div>
      <div className="position-relative">
      {
        allpendingData.isLoading && <div className="loader_bg"><Loader /></div>
      }
      {
        prevReSubmission.isLoading && 
      <div className="loader-load">
      <Loader />
      </div>
  }
      <div className='table-bg'>
        <Table
          gridTemplateColumnsHeader="6% 8% 10% 8% 10% 13% 9% 6% 10% 6% 6% 7%"
          gridTemplateColumnsBody="6% 8% 10% 8% 10% 13% 9% 6% 10% 6% 6% 7%"
          columns={TemplateTableData}
          data={tableData}
        />

        <Pagination
          current={current}
          onChange={onChange}
          total={totalData} />

      </div>
      </div>
      <DefaultModal
        show={showsubmission}
        onHide={() => setShowsubmission(false)}
        className="remark-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>Add Submission</h4>
          <div className="close-icon">
            <img src="/Images/closeBlack.svg" onClick={() => setShowsubmission(false)} />
          </div>
        </div>
        <SubmissionModal 
        transtionId={transtionId}
        mClose={setShowsubmission}
        />
      </DefaultModal>
      <DefaultModal
        show={show}
        onHide={() => setShow(false)}
        className="remark-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>Add Remarks</h4>
          <div className="close-icon">
            <img src="/Images/closeBlack.svg" onClick={() => setShow(false)} />
          </div>
        </div>
        <RemarksModal 
         requestId={currentRequest}
         mClose={setShow}
        />
      </DefaultModal>
      
    </div>
  )
}

export default AllPendingTable