import React from 'react'
import CategoryNode from './components/CategoryNode'
import { Helmet } from 'react-helmet'

const SampleCategory = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sample Category | Sample Dashboard</title>
      </Helmet>
      <CategoryNode />
    </div>
  )
}

export default SampleCategory
