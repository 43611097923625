


import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import WeekAccordion from './WeekAccordion';
import { useDispatch, useSelector } from 'react-redux';
import { getworkloadData } from '../../../store/user/actions';
import DefaultModal from '../../../component/DefaultModal/DefaultModal';
import EmployeeModal from './EmployeeModal';
import UserNameImage from '../../../component/UserNameImage/UserNameImage';

const WorkloadWeek = (props) => {

  const [show, setShow] = useState(false);
  const workloadData = useSelector(state => state.workloadData)
  const [workloadBox, setWorkloadBox] = useState(true);
  const [userWorkData, setuserWorkData] = useState([]);
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getworkloadData(props.selectBox))
  }, [props.selectBox])

  const workValue = (selectValue) => {
    setWorkloadBox(selectValue)
  }
  const workData = (data) => {
    setShow(true)
    setuserWorkData(data)
  }


  return (
    <div className='workload-section'>

      <div className='workload-scroll'>
        {
          workloadData.data.map((user) => {
            return user.map((week) => {
              return (<div className='col-lg-4 col-md-4 mb-4'>
                <div className={`fllter-con row justify-content-center ${props.selectBox.userId ? 'filter-con-with-user' : 'filter-con-without-user'}`}>
                  {
                    props.selectBox.userId=="" ? 
                    week.user_workload.map((item, index) => {
                      if (index > 2) {
                        return <></>
                      }
                      return (<div className='col-lg-3 user_im_w' key={index}>
                         <UserNameImage 
                        name={item.name}
                        image={item.image}
                        width= "64px"
                        height= "64px"
                        margin="0 auto"
                        title={item.name}
                        />
                        <h6>{item.hours}hr</h6>
                        <h6>Workload</h6>

                      </div>)
                    }):<></>
                  }
                  {week.user_workload.length && props.selectBox.userId==""  ? <div><Link onClick={() => workData(week.user_workload)}>View all employee</Link></div> : <></>}
               
                  </div>

                <div className='accordion-box'>
                 <div className=''>
                  <h3>Week-{week.week} <span className='month_name'> {week.date}</span></h3>
                  
                  </div> 
                  <div className='hours'>
                    <div className='allocate-hour'>
                      <h5>Allocated Hours</h5>
                      <h6>{week.allocatedHours} Hr</h6>
                    </div>
                    <div className='allocate-hour'>
                      <h5>Available Hours</h5>
                      <h6 style={{ color: "#00BB5A" }}>{week.availableHours} Hr</h6>
                    </div>
                    <div className='allocate-hour'>
                      <h5>Pending Work Hours</h5>
                      <h6 style={{ color: "#F00" }}>{week.pandingHours} Hr</h6>
                    </div>
                    <div className='allocate-hour'>
                      <h5>Completed Work Hours</h5>
                      <h6 style={{ color: "#00BB5A" }}>{week.completedHours} Hr</h6>
                    </div>
                    <WeekAccordion data={week.data} />
                  </div>
                </div>
              </div>)
            })
          })
        }

      </div>
      <DefaultModal
        show={show}
        onHide={() => setShow(false)}
        className="person-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>WORKLOAD</h4>
          <div className="close-icon">
            <img src="/Images/closeBlack.svg" onClick={() => setShow(false)} alt="" />
          </div>
        </div>

        <EmployeeModal
          workValue={workValue}
          userWorkData={userWorkData}
        />
      </DefaultModal>
    </div>
  )
}

export default WorkloadWeek



