
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import Table from '../../../component/Table/Table'
import SubmissionModal from '../../MyPendingTask/components/SubmissionModal'
import DefaultModal from '../../../component/DefaultModal/DefaultModal'
import { getsubmissionData } from "../../../store/user/actions";
import Loader from "../../../component/Loader";
import RemarksModal from "../../Dashboard/component/RemarksModal";
import { Pagination } from "antd";
import UserNameImage from "../../../component/UserNameImage/UserNameImage";


// import SubmissionModal from "./SubmissionModal";
// import RemarksModal from "./RemarksModal";

const SubmissionTable = (props) => {
    const submissionData = useSelector(state => state.submissionData)
    const dispatch = useDispatch()
    const { id } = useParams();
    const [showsubmission, setShowsubmission] = useState(false);
    const [currentRequest, setCurrentRequest] = useState(0);
    const [current, setCurrent] = useState(1);
    const [totalData, setTotalData] = useState(0);
    const [tableData, setTableData] = useState([])
    const [show, setShow] = useState(false);
    const [data,setData] = useState({list:[],userTask:[]});
    useEffect(() => {
        dispatch(getsubmissionData(props.selectBox))
      }, [props.selectBox])

    const onChange = (page) => {
        setCurrent(page);
        dispatch(getsubmissionData({...props.selectBox,page}))
      };
      useEffect(()=>{
        setTotalData(submissionData.data.total_submission)
        let sno = (current-1)*10+1;
    const FakeTableData = submissionData.data.list.map((item,i)=>{
        return [
            <>
                <h6>{i+1}</h6>
            </>,
            <div>
                <h6>{item.submission_id}</h6>
            </div>,

            <div className='currnt-nodes'>
                <UserNameImage
                    width="40px"
                    height="40px"
                    margin="0 auto"
                    name={item.user_name}
                    image={item.user_image}
                />
                {/* <img src={item.user_image} /> */}
                <span className='msg-noti'>{item.submission_no}</span>
                <h6>{item.node_name}</h6>
                <h6>{item.user_name}</h6>
            </div>,
            <>
                <h6 className="pb-2">Original <br/>
                    {item.plan_date.original}</h6>

                <h6 className="pb-2">Current <br/>
                {item.plan_date.plan}</h6>

                <h6>Actual<br/>
                {item.plan_date.actual}
                </h6>
            </>,
            <>
                <h6>{item.collection_name}</h6>
            </>,

            <div>
                <h6>{item.sample_numbar}</h6>
            </div>,
            <>
                <h6>{item.delay} Days</h6>
            </>,
            <div>
                <h6> {item.current_plan_date} </h6>
            </div>,

            <div class="bg-image-sm">
                <div class="team-pic">
                    <div className="team-member">
                        <img src="Images/setting.svg" />
                        <span class="tooltiptext-sm">
                        {/* <Link onClick={() => {setShow(true);setCurrentRequest(item.sampleRequestId)}}>Add Remarks</Link>
                            <Link to={"/node-workload/"+item.id}>Current node Workload</Link>
                            <Link to={"/sample-node/"+item.sampleRequestId}>Sample Nodes</Link> */}
                            <Link to={"/view-submission-details/"+item.id}>View Submission Details</Link>
                             {/* <Link to={"/submission-form/"+id}>Add Submission Current Node</Link>  */}

                        </span>
                    </div>
                </div>
            </div>

        ]
    })
    setTableData(FakeTableData)
},[submissionData])

    const TemplateTableData = [

        {
            heading: "Sr.No.",
        },
        {
            heading: "Submission Id",
        },
        {
            heading: "Node",
        },
        {
            heading: "Plan Date",
        },
        {
            heading: "Collection Name",
        },
        {
            heading: "Sample Reference Number",
        },
        {
            heading: "Delay",
        },
        {
            heading: "Current Planed Date",
        },
        {
            heading: "Actions",
        },
    ];
    return (
        <div>

            <div className='padding-section'>
            <div className='list_flex'>
                {
                    submissionData.data.userTask.map((item, i) => (
                        <div className='list_box_user'>
                            <div className='fllter-con-submisson' style={{ width: "80px" }}>
                            <UserNameImage 
                             width="64px"
                             height="64px"
                             margin="0 auto"
                             title={item.name}
                                name={item.name}
                                image={item.image}
                                />
                                {/* <img src={item.image} /> */}
                                {/* <h6 className='pt-2'>{item.name}</h6> */}
                                <h6 className='pt-2'>{item.tasks} Submission</h6>
                                <h6 className="pt-1">{item.hours} Hr</h6>
                            </div>
                        </div>
                    ))
                }

            </div>
        </div>
        <div className="position-relative">
        {
         submissionData.isLoading && <div className="loader_bg"><Loader /></div>
         }
            <div className='table-bg'>
                <Table
                    gridTemplateColumnsHeader="10% 10% 15% 10% 10% 15% 10% 10% 10%"
                    gridTemplateColumnsBody="10% 10% 15% 10% 10% 15% 10% 10% 10%"
                    columns={TemplateTableData}
                    data={tableData}
                />
                   <Pagination
                    current={current}
                    onChange={onChange}
                    total={totalData} />
            </div>
            <DefaultModal
                show={show}
                onHide={() => setShow(false)}
                className="remark-modal"
                style={{ paddingRight: "0px" }}
                centered={false}
                showHeader={false}
                size=""
                showFooter={false}
            >
                <div className="modal-heading">
                <h4>Add Remarks</h4>
                <div className="close-icon">
                    <img src="/Images/closeBlack.svg" onClick={() => setShow(false)} />
                </div>
                </div>
                <RemarksModal 
                mClose={setShow}
                requestId={currentRequest}
                />
            </DefaultModal>
            {/* <DefaultModal
                show={showsubmission}
                onHide={() => setShowsubmission(false)}
                className="remark-modal"
                style={{ paddingRight: "0px" }}
                centered={false}
                showHeader={false}
                size=""
                showFooter={false}
            >
                <div className="modal-heading">
                    <h4>Add Submission</h4>
                    <div className="close-icon">
                        <img src="/Images/closeBlack.svg" onClick={() => setShowsubmission(false)} />
                    </div>
                </div>
                <SubmissionModal /> 
            </DefaultModal> */}
            </div>
        </div>
    )
}

export default SubmissionTable


