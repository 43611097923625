import React from 'react'
import ToggleSwitch from './ToggleSwitch'

const TrailNode = () => {
  return (
    <div className='trail'>
    <div className='bg-boxshadow'></div>
    <h3>Traill No. 01</h3>
    <div className='row'>
        <div className='col-lg-2 col-md-2'>
            <div className='node-default'>
                <div className='circle-bg'>
                    <img src='Images/plus.svg' />
                </div>
                <div className='border-right'></div>
                <div className='mt-4'>
                    <ToggleSwitch label="O" />
                </div>
            </div>
        </div>
        <div className='col-lg-2 col-md-2'>
            <div className='node-default'>
                <div className='circle-bg'>
                    <img src='Images/plus.svg' />
                </div>

                <div className='border-right'></div>
                <div className='mt-4'>
                    <ToggleSwitch label="1" />
                </div>
            </div>
        </div>
        <div className='col-lg-2 col-md-2'>
            <div className='node-default'>
                <div className='circle-bg'>
                    <img src='Images/plus.svg' />
                </div>
                <div className='border-right'></div>
                <div className='mt-4'>
                    <ToggleSwitch label="2" />
                </div>
            </div>
        </div>
        <div className='col-lg-2 col-md-2'>
            <div className='node-default'>
                <div className='circle-bg'>
                    <img src='Images/plus.svg' />
                </div>
                <div className='border-right'></div>
                <div className='mt-4'>
                    <ToggleSwitch label="3" />
                </div>
            </div>
        </div>
        <div className='col-lg-2 col-md-2'>
            <div className='node-default'>
                <div className='circle-bg'>
                    <img src='Images/plus.svg' />
                </div>

                <div className='border-right'></div>
                <div className='mt-4'>
                    <ToggleSwitch label="3" />
                </div>
            </div>
        </div>
        <div className='col-lg-2 col-md-2'>
            <div className='node-default'>
                <div className='circle-bg'>
                    <img src='Images/plus.svg' />
                </div>
                <div className='mt-4'>
                    <ToggleSwitch label="3" />
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default TrailNode
