import React, { useState } from 'react'
import DashboardSelect from '../Dashboard/component/DashboardSelect'
import SubmissionTable from './components/SubmissionTable'
// import TaskProfile from '../AllPendingTask/components/TaskProfile'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { getUser } from '../../util/Common'
import SubmissionSelect from './components/SubmissionSelect'
import DefaultModal from '../../component/DefaultModal/DefaultModal'
import ImportModal from '../Dashboard/component/ImportModal'
import { getsubmissionData } from '../../store/user/actions'
import { useDispatch } from 'react-redux'

const SubmissionScreen = () => {
  const [selectBox, setSelectBox] = useState(true);
  const [isModalImport, setIsModalImport] = useState(false);
  const dispatch = useDispatch()
  const filterValue = (selectValue) => {
    setSelectBox(selectValue)
  }
  const downloadExcel = async() => {
    let excel = process.env.REACT_APP_WEB_URL+"/submission_export_data?"
    const user = await getUser() 
    selectBox.userId=user.id
    for (const key in selectBox) {
      if (selectBox.hasOwnProperty(key)) {
        excel+=`${key}=${selectBox[key]}&`
      }
    }
    window.open(excel,"_blank")
  }
  const refreshNodeData = () => {
    dispatch(getsubmissionData())
  }
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Submission | Sample Dashboard</title>
      </Helmet>
      <div className='d-flex align-items-baseline justify-content-between pb-4'>
        <div className="heading">
          <h2>Submission Screen</h2>
        </div>
        <div className='download-but'>
          <button onClick={()=>downloadExcel()}><img src="Images/document-download.svg" />Download Excel</button>
          <div className='view-but'>
            <Link to="/workload">
              view workload
            </Link>
          </div>
          <Link className="blue_but ml-2" style={{marginLeft:"7px"}}onClick={() => setIsModalImport(true)}><img src="/Images/document-download.svg" /> Import Submission</Link>
        </div>
      </div>
      {/* <DashboardSelect
        filterValue={filterValue}
      /> */}
      <SubmissionSelect 
      filterValue={filterValue}
      />
      {/* <TaskProfile /> */}
      <SubmissionTable 
      selectBox={selectBox}
      />
          <DefaultModal
        show={isModalImport}
        onHide={() => setIsModalImport(false)}
        className="remark-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>Import File</h4>
          <div className="close-icon">
            <img src="/Images/closeBlack.svg" onClick={() => setIsModalImport(false)} />
          </div>
        </div>
        <ImportModal 
         mClose={setIsModalImport}
        refreshNodeData={refreshNodeData}
        //  requestId={currentRequest}
        />
      </DefaultModal>
    </div>
  )
}

export default SubmissionScreen
