
import React, { useEffect, useState } from 'react'
import DashboardSelect from '../Dashboard/component/DashboardSelect'
import { Link, useParams } from 'react-router-dom'
import DefaultModal from '../../component/DefaultModal/DefaultModal'
import RemarksModal from '../Dashboard/component/RemarksModal'
import { useDispatch, useSelector } from 'react-redux'
import { getcollectionData } from '../../store/user/actions'
import UserNameImage from '../../component/UserNameImage/UserNameImage'



const formatDate = (dateString) => {
  if(dateString!=null){
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
  }else{
    return "";
  }
};
const Gallery = () => {
  const [show, setShow] = useState(false);
  const { id } = useParams();
  const [selectBox, setSelectBox] = useState({sample_collection_id:id});
  const [currentRequest, setCurrentRequest] = useState(0);
  const collectionData = useSelector(state => state.collectionData)
  const dispatch = useDispatch()

  useEffect(() => {
    // props.selectBox
    dispatch(getcollectionData(selectBox))
  }, [selectBox])
  
useEffect(() =>{
    let data = { ...selectBox };
    data.sample_collection_id=id
    setSelectBox(data)
},[])
  const filterValue = (selectValue) => {
    setSelectBox(selectValue)
    
  }
  return (
    <div>
      <div className='d-flex align-items-baseline pb-4'>
        <div className="heading">
          <Link to="/collections">
          <img src="/Images/arrow-left.svg" />
          </Link>
        </div>
        <h2>Gallery Screen</h2>
      </div>
      <DashboardSelect
        filterValue={filterValue}
      />
      <div className='image-gallery'>
        <div className='row'>
          { collectionData.data.list.map((item, i) => (
              <div className='col-md-4 col-lg-4 col-12' key={i}>
                <div className='img__wrap'>
                <img src={item.samplePicture==""?"/Images/dummy.jpg":process.env.REACT_APP_IMG_URL+item.samplePicture} className='clothes-im' alt=""/>
                 
                  {/* <div className='tooltiptextBox'> */}
                  <div class="img__description_layer">
                    <div className='img__description'>
                    <h6>Collection Name : <b>{item.collectionName}</b></h6>
                    <h6>Request No : <b>{item.sampleRequestId}</b></h6>
                    <div className='border-bottom-tooltip'></div>
                    <p>Start Dates:</p>
                    <div className='row'>
                     <div className='col-lg-4 col-md-4'>
                     <h6><b>Original - {formatDate(item.startDate.orignalDate)}</b></h6>
                      </div>
                      <div className='col-lg-4 col-md-4'>
                      <h6><b>Current - {formatDate(item.startDate.currentDate)}</b></h6>
                      </div>
                      <div className='col-lg-4 col-md-4'>
                      <h6><b>Actual - {formatDate(item.startDate.actualDate)}</b></h6>
                      </div>
                    </div>
                    <div className='border-bottom-tooltip'></div>
                    <p>End Dates:</p>
                    <div className='row'>
                    <div className='col-lg-4 col-md-4'>
                    <h6><b>Original - {formatDate(item.endDate.orignalDate)}</b></h6>
                      </div>
                      <div className='col-lg-4 col-md-4'>
                      <h6><b>Current - {formatDate(item.endDate.currentDate)}</b></h6>
                      </div>
                      <div className='col-lg-4 col-md-4'>
                      <h6><b>Actual - {formatDate(item.endDate.actualDate)}</b></h6>
                      </div>
                    </div>
                    <div className='border-bottom-tooltip'></div>
                    <p>Current Pending Nodes</p>
                    <div className='row'>
                    { item.currentPandingNode.map((node) => {
                        return<div className='col-lg-6 col-md-6'>
                        <div className='t-pro'>
                        <UserNameImage
                          width="24px"
                          height="24px"
                          name={node.userName}
                          image={node.userImage}
                      />
                        {/* <img src={node.userImage==""?"/Images/dummy.jpg":node.userImage} /> */}
                        <span className='msg-noti-2'>{node.trail}</span>
                        <div className='gallery_top'>
                          <h5>
                          {node.userName}
                          </h5>
                          <h6>{node.nodeName} {node.delay}</h6>
                        </div>
                      </div>
                      </div>
                     })
                    }
                    </div>
                    <div className='border-bottom-tooltip'></div>
                    <p>Next Node</p>
                    <div className='row'>
                    { item.nextNode.map((node) => {
                        return <div className='col-lg-6 col-md-6'>
                      <div className='t-pro'>
                      <UserNameImage
                          width="24px"
                          height="24px"
                          fontSize="8px"
                          name={node.userName}
                          image={node.userImage}
                      />
                      {/* <img src={item.nextNode.userImage==""?"/Images/dummy.jpg":item.nextNode.userImage} /> */}
                        <span className='msg-noti-2'>1</span>
                        <div className='gallery_top'>
                          <h5>
                          {node.userName}
                          </h5>
                          <h6>{node.nodeName} {node.delay}</h6>
                        </div>
                      </div>
                      </div>
                      })
                    }
                      <div className='col-lg-6'>
                      <div className='t-pro pt-2 mb-0' style={{ top: "-10px" }}>
                        <div>
                          <h6 style={{ fontWeight: "500", lineHeight: "2px" }}>
                          Strikes / Rewards
                          </h6>
                          <h6>{item.rewardStrike.reward}  Strike Point / {item.rewardStrike.strike} Rewards</h6>
                        </div>
                      </div>
                      </div>
                    </div>
                    
                    <div className='border-bottom-tooltip'></div>
                    <div className='d-flex p-2'>
                      <div style={{ maxWidth: "162px", minWidth: "162px" }}>
                        <h6 style={{ fontWeight: "500", lineHeight: "5px" }}>Current Status</h6>
                        <h6>{item.status}</h6>
                      </div>
                      <div>
                        <h6 style={{ fontWeight: "500", lineHeight: "5px" }}>Net Delay</h6>
                        <h6>{item.netDelay} Days</h6>
                      </div>
                    </div>
                    <div className='three_but'>
                      <Link onClick={() =>{setShow(true);setCurrentRequest(item.sampleId);}}>Add Remarks</Link>
                      <Link to="/workload">Workload</Link>
                      <Link to={"/sample-node/"+item.sampleId}>Sample Nodes</Link>
                    </div>
                  </div>
                {/* </div> */}
                </div>
                </div>
              </div>
            ))
          }

        </div>
        <DefaultModal
        show={show}
        onHide={() => setShow(false)}
        className="remark-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>Add Remarks</h4>
          <div className="close-icon">
            <img src="/Images/closeBlack.svg" onClick={() => setShow(false)} />
          </div>
        </div>
        <RemarksModal 
        requestId={currentRequest}
        mClose={setShow}
        />
      </DefaultModal>
      </div>
    </div>
  )
}

export default Gallery
