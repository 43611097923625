import React, { useState, useEffect } from 'react'
import Box from '../../../component/Box';

import NodeSelectTable from './NodeSelectTable';
import AssignTable from './AssignTable';
import { getnodeUserUpdateBulk, getrequestList, getuserListByDesignation, getuserWorkLoadData } from "../../../store/user/actions";
import { useDispatch, useSelector } from "react-redux";

const SelectTab = () => {
  const [selectedSegment, setselectedSegment] = useState("Information");
  const initialValues = {
    sample_category_node_transaction_id: "",
  };
  const [selectedValue, setSelectedValue] = useState([]);
  const [userList, setUserList] = useState([]);
  const [userWorkload, setUserWorkload] = useState([]);
  const [payload, setPayload] = useState([]);
  const requestList = useSelector(state => state.requestList)
  const dispatch = useDispatch()
  const userListByDesignation = useSelector(state => state.userListByDesignation)
  const userWorkLoadData = useSelector(state => state.userWorkLoadData)

  useEffect(() => {
    const localdata = JSON.parse(localStorage.getItem("selectedPayload"))
    setPayload(localdata)
    dispatch(getrequestList(localdata))
  }, [])

  useEffect(() => {
    if (requestList.isSuccess) {
      const req_ids = requestList.data.list.map((item) => item.id)
      const olddata = { ...payload }
      olddata.ids = req_ids.join(',')
      setPayload(olddata);
    }
  }, [requestList])
  const handleSearch = (value, index, operator_designation_id) => {
    const oldData = [...userList];
    oldData[index] = []
    setUserList(oldData)
    dispatch(getuserListByDesignation({ designation_id: operator_designation_id, search: value, index }))
  };

  useEffect(() => {
    const oldData = [...userList];
    oldData[userListByDesignation.data.index] = userListByDesignation.data.list
    setUserList(oldData)
  }, [userListByDesignation])

  const handleChange = (id, index, deig_id) => {
    const oldData = [...selectedValue];
    oldData[index] = { userid: id, deig_id: deig_id };
    setSelectedValue(oldData)
    dispatch(getuserWorkLoadData({ id, index }))
  };

  useEffect(() => {
    const oldData = [...userWorkload];
    oldData[userWorkLoadData.data.index] = userWorkLoadData.data.list
    setUserWorkload(oldData)
  }, [userWorkLoadData])

  const submitHendle = () => {
    dispatch(getnodeUserUpdateBulk({ selectedUser: selectedValue, request_id: payload.ids }))
  }

  const removeRequest = async (id) => {
    const updatedid = payload.ids.split(",").filter(number => number != id);
    setPayload({ ids: updatedid.join(",") });
    dispatch(getrequestList({ ids: updatedid.join(",") }))
    setSelectedValue([]);
    setUserWorkload([])
    setUserList([])
  }
  const setSegment = (segment) => {
    setselectedSegment(segment);
  };
  return (
    <div>
      <Box display="flex" alignItems="center" cursor="pointer" justifyContent="left">
        <Box fontSize="22px" fontWeight={700}>{selectedSegment === "Information"}</Box>
        <ul className="d-flex tab_setting">
          <li
            className="information"
            style={{
              fontWeight: selectedSegment === "Information" ? "600" : "600",
              backgroundColor:
                selectedSegment === "Information" ? "#33DABD" : "#D9D9D9",
              color: selectedSegment === "Information" ? "#1C1C1C" : "#1C1C1C",
              marginRight: selectedSegment === "Information" ? "7px" : "7px",
              padding: selectedSegment === "Information" ? "7px" : "7px",
              borderRadius:
                selectedSegment === "Information"
                  ? "5px 5px 0px 0px"
                  : "5px 5px 0px 0px",
            }}
            onClick={() => setSegment("Information")}
          >
            Assign Users{" "}
          </li>
          <li
            className="screen-details"
            style={{
              fontWeight: selectedSegment === "Screen details" ? "600" : "600",
              backgroundColor:
                selectedSegment === "Screen details" ? "#33DABD" : "#D9D9D9",
              color: selectedSegment === "Screen details" ? "#1C1C1C" : "#1C1C1C",
              borderRadius:
                selectedSegment === "Screen details"
                  ? "5px 5px 0px 0px"
                  : "5px 5px 0px 0px",
            }}
            onClick={() => setSegment("Screen details")}
          >

            Assign Criticality{" "}
          </li>


        </ul>
      </Box>
      <Box className='tab_body'>
        {selectedSegment === "Information" ? (
          <NodeSelectTable
            setSegment={setSegment}
            requestList={requestList}
            removeRequest={removeRequest}
            handleSearch={handleSearch}
            handleChange={handleChange}
            userList={userList}
            userWorkload={userWorkload}
            submitHendle={submitHendle}
          />
        ) : null}
        {selectedSegment === "Screen details" ? (
          <AssignTable
            setSegment={setSegment}
            requestList={requestList}
            ids={payload.ids}
          />
        ) : null}


      </Box>
    </div>
  )
}

export default SelectTab

