import React from 'react'
import ViewTable from './component/ViewTable'
import { useNavigate } from 'react-router-dom';

const ViewSubmissionDetails = () => {
  const navigate = useNavigate();
  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <div>
      <div className='d-flex align-items-baseline pb-4'>
          <div className="heading">
            <div onClick={handleGoBack}>
            <img src="/Images/arrow-left.svg" />
            </div>
          </div>
          <h2>Submission Details</h2>
        </div>
       <ViewTable />
    </div>
  )
}

export default ViewSubmissionDetails
