import React, { useEffect, useState } from 'react'
import { Select, DatePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getfilterData } from '../../../store/user/actions';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import { Link } from 'react-router-dom';

const RejectionFilter = ({filterValue, hiddenInput={user:false}}) => {
    
    const initialValues = {
        userId: "",
        sampleRequestNo: "",
        sample_collection_id: "",
        sampleCategoryId: "",
        currentPendingNodeId: "",
        next_node_id: "",
        pre_node_id: "",
        actual_date_form:"",
        actual_date_to:"",
        actualDate:null,
        plan_date_form:"",
        plan_date_to:"",
        original_date_form:"",
        original_date_to:"",
        originalDate:"",
        trail:"",
        delay:"",
        submissionNumber:"",
      };
      const rangeDate = {
        actualDate:[new Date(),new Date()],
        planDate:[new Date(),new Date()],
        originalDate:[new Date(),new Date()],
    
      }
      const [formValues, setFormValue] = useState(initialValues);
      const [rangePickerDate, setrangePickerDate] = useState(rangeDate);
      const filterData = useSelector(state => state.filterData.data)
      const dispatch = useDispatch()
    
      useEffect(() => {
        dispatch(getfilterData(formValues))
        filterValue(formValues)
      }, [formValues])
      const onChange = (name, date) => {
        let data = { ...rangePickerDate };
        data[name] = date;
        setrangePickerDate(data);
        var currentDate = new Date(date[0]);
        // Get the year, month, and day from the date
        var year = currentDate.getFullYear();
        var month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is 0-based
        var day = String(currentDate.getDate()).padStart(2, '0');
        // Format the date in "Y-m-d" format
        var toDate = `${year}-${month}-${day}`;
    
        var currentDate = new Date(date[1]);
        // Get the year, month, and day from the date
        var year = currentDate.getFullYear();
        var month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is 0-based
        var day = String(currentDate.getDate()).padStart(2, '0');
    
        // Format the date in "Y-m-d" format
        var fromDate = `${year}-${month}-${day}`;
        let formdata = { ...formValues };
        formdata[name+"From"] = toDate;
        formdata[name+"To"] = fromDate;
        // formdata[name]=date
        setFormValue(formdata);
      };
      
    
      const handleChange = (name, value) => {
        let data = { ...formValues };
        data[name] = value;
        setFormValue(data);
      };
    

      const sampleRequestOption = Object.entries(filterData.sample_request).map(([value, label]) => ({
        value: parseInt(value, 10),
        label,
      }));
      const sampleCollectionOption = Object.entries(filterData.sample_collection).map(([value, label]) => ({
        value: parseInt(value, 10),
        label,
      }));
      const sampleNodesOption = Object.entries(filterData.sample_nodes).map(([value, label]) => ({
        value: parseInt(value, 10),
        label,
      }));
     
  return (
    <div>
         <div className='row mt-4'>
      {/* {hiddenInput.user !== undefined && hiddenInput.user == true?'':
     
 } */}
 <div className='col-md-3 col-lg-3 col-12 mb-2'>
      <label className='select-label'>Sample Request Number</label>
        <Select
          defaultValue="Sample Request Number"
          value={formValues.sampleRequestNo}
          onChange={(e) => handleChange("sampleRequestNo", e)}
          options={sampleRequestOption}
          showSearch
          optionFilterProp="children"
          filterOption={(input, sampleRequestOption) => (sampleRequestOption?.label ?? '').includes(input)}
          filterSort={(sampleRequestOption) =>
            (sampleRequestOption?.label ?? '').toLowerCase().localeCompare((sampleRequestOption?.label ?? '').toLowerCase())
          }
        />
      </div>
      
      <div className='col-md-3 col-lg-3 col-12 mb-2'>
      <label className='select-label'>Collection Name</label>
        <Select
          defaultValue="Collection Name"
          value={formValues.sample_collection_id}
          onChange={(e) => handleChange("sample_collection_id", e)}
          options={sampleCollectionOption}
          showSearch
          optionFilterProp="children"
          filterOption={(input, sampleCollectionOption) => (sampleCollectionOption?.label ?? '').includes(input)}
          filterSort={(sampleCollectionOption) =>
            (sampleCollectionOption?.label ?? '').toLowerCase().localeCompare((sampleCollectionOption?.label ?? '').toLowerCase())
          }
        />
      </div>
      <div className='col-md-3 col-lg-3 col-12 mb-2' >
      <label className='select-label'>Current Pending Node</label>
        <Select
          defaultValue="Current Pending Node"
          value={formValues.currentPendingNodeId}
          onChange={(e) => handleChange("currentPendingNodeId", e)}
          options={sampleNodesOption}
          showSearch
          optionFilterProp="children"
          filterOption={(input, sampleNodesOption) => (sampleNodesOption?.label ?? '').includes(input)}
          filterSort={(sampleNodesOption) =>
            (sampleNodesOption?.label ?? '').toLowerCase().localeCompare((sampleNodesOption?.label ?? '').toLowerCase())
          }
        />
      </div>
      <div className='col-md-3 col-lg-3 col-12 mb-2' >
      <label className='select-label'>Next Node</label>
        <Select
          defaultValue="Next Node"
          value={formValues.nextNodeId}
          onChange={(e) => handleChange("next_node_id", e)}
          options={sampleNodesOption}
          showSearch
          optionFilterProp="children"
          filterOption={(input, sampleNodesOption) => (sampleNodesOption?.label ?? '').includes(input)}
          filterSort={(sampleNodesOption) =>
            (sampleNodesOption?.label ?? '').toLowerCase().localeCompare((sampleNodesOption?.label ?? '').toLowerCase())
          }
        />
      </div>
     
      <div className='col-md-3 col-lg-3 col-12 mb-2'>
      <label className='select-label'>Actual Date</label>
         <DateRangePicker
          //  placeholder="Actual Date"
            onChange={(e) => onChange("actualDate", e)}
            value={rangePickerDate.actualDate}
            clearIcon	= {false}
          
          />
      </div>
      <div className='col-md-3 col-lg-3 col-12 mb-2'>
      <label className='select-label'>Current Planned Date</label>
      <DateRangePicker
         placeholder="Current Planned Date"
            onChange={(e) => onChange("planDate", e)}
            value={rangePickerDate.planDate}
            yearAriaLabel="Year"
            clearIcon	= {false}
          />
      </div>
      <div className='col-md-3 col-lg-3 col-12 mb-2'>
      <label className='select-label'>Original Planned Date</label>
      <DateRangePicker
         placeholder="Original Planned Date"
            onChange={(e) => onChange("originalDate", e)}
            value={rangePickerDate.originalDate}
            yearAriaLabel="Year"
            clearIcon	= {false}
          />
      </div>
      <div className='col-md-3 col-lg-3 col-12 mb-2'>
      <label className='select-label'>Previous Node</label>
        <Select
          defaultValue="Previous Node"
          value={formValues.previousNodeId}
          onChange={(e) => handleChange("pre_node_id", e)}
          options={sampleNodesOption}
          showSearch
          optionFilterProp="children"
          filterOption={(input, sampleNodesOption) => (sampleNodesOption?.label ?? '').includes(input)}
          filterSort={(sampleNodesOption) =>
            (sampleNodesOption?.label ?? '').toLowerCase().localeCompare((sampleNodesOption?.label ?? '').toLowerCase())
          }
        />
      </div>
      <div className='col-md-3 col-lg-3 col-12 mb-2'>
     <div className='clearDatas pt-0'>
       <Link onClick={()=> setFormValue(initialValues)} className='clear_data'>Clear Data</Link>
       </div>
      </div>
    </div>
    </div>
  )
}

export default RejectionFilter



