import React, { useEffect, useState } from "react";
import Searchbar from '../component/Searchbar/Searchbar'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUser, removeUserSession } from "../util/Common";

const Header = (props) => {
  // const user = useSelector(selectUser)
  const [value, setValue] = useState("");
  const navigate = useNavigate();
  const user = getUser();
  const handleLogout = (e) => {
    removeUserSession();
      navigate("/login");
  }



  return (
    
    <div className="header-bg">
      <div>
      </div>
       {/* <Searchbar 
            value={value}
            setValue={(e) => setValue(e.target.value)}
            placeholder="Search"
            fontSize="12px"
            backgroundColor="#F5F5F5"
            borderRadius="4px"
            searchIcon
            width="549px"
            height="48px"
            position="relative"
            padding="0px 20px"

          /> */}
         <div className="logout-profile">
          <img src={user.profile_image}/>
          <div>
          <h6>{user.firstname}</h6>
          <button onClick={(e) => handleLogout(e)}>Logout</button>
          </div>
         </div>
    </div>
  )
}

export default Header
