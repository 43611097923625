
import React, { useEffect, useState } from "react";

const CheckBox= ({selectRequest,onChange,id,sno,disabled}) => {
    const [checked, setChecked] = useState(false)
    useEffect(()=>{
        if(disabled){
        const checkbox = selectRequest.find((item) => item.id === id);
        setChecked(checkbox ? checkbox.checked : false);
        }
    },[selectRequest])
    return (
        <label className="container_checkbox" data-id={disabled}><h6>{sno}</h6>
        {
            disabled?<input type="checkbox" checked={checked} onChange={e=>onChange(id)} />:<input type="checkbox" disabled={disabled} checked={checked} onChange={e=>onChange(id)} />
        }
            
            <span className="checkmark"></span>
        </label>
    );
}
export default CheckBox;