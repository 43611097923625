
import React, { useState, useMemo, useEffect, useRef } from 'react';
import { getcalendarData, getcalenderNewDetail, getmonthPending, getnewCalendar } from '../../../store/user/actions';


import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import CalendarSidebar from './CalendarSidebar';
import EventData from './EventData.json'

// import WeekEventData from './WeekEventData.json'
import CalendarHeader from './CalendarHeader';

const CustomBigCalendar = ({ selectBox }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [defaultView, setDefaultView] = useState('month')
  const [defaultDate, setDefaultDate] = useState(new Date().toDateString())
  const [currentWeek, setCurrentWeek] = useState(1); // Add a state variable to track the current week

  const monthPending = useSelector(state => state.monthPending)
  const newCalendar = useSelector(state => state.newCalendar)

  const dispatch = useDispatch()
  const [currentView, setCurrentView] = useState('week'); // 'month', 'week', 'day'

  const [weekDates, setWeekDates] = useState([]);
  const [monthWeek, setmonthWeek] = useState([]);
  const [dateRangeDetail, setDateRangeDetail] = useState([]);

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleSidebarClick = (daterange) => {
    setIsSidebarOpen(!isSidebarOpen);
    setDateRangeDetail({ date_range: daterange })
    // dispatch(getcalenderNewDetail())
  };
  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
  };

  //days
  useEffect(() => {
    const today = new Date(defaultDate);
    const startOfCurrentWeek = new Date(today);
    startOfCurrentWeek.setDate(today.getDate() - today.getDay()); // set to the first day of the week (Sunday)
    const endOfCurrentWeek = new Date(startOfCurrentWeek);
    endOfCurrentWeek.setDate(startOfCurrentWeek.getDate() + 6); // set to the last day of the week (Saturday)
    const datesArray = [];
    for (let date = new Date(startOfCurrentWeek); date <= endOfCurrentWeek; date.setDate(date.getDate() + 1)) {
      // date = new Date(date);
      datesArray.push(date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, '0') + '-' + String(date.getDate()).padStart(2, '0'));
    }
    setWeekDates(datesArray);
    if (currentView == "day") {
      dispatch(getnewCalendar({ type: "day", date: datesArray,...selectBox}));
    }
  }, [defaultDate]);

  //weeks
  useEffect(() => {
    const week = [];
    var currentDate = new Date(defaultDate);
    var oneJan = new Date(currentDate.getFullYear(), 0, 1);
    var numberOfDays = Math.floor((currentDate - oneJan) / (24 * 60 * 60 * 1000));
    var weekNumber = Math.ceil((currentDate.getDay() + 1 + numberOfDays) / 7);
    for (let i = weekNumber; i < weekNumber + 6; i++) {
      var weekno = i;
      var year = new Date(defaultDate).getFullYear();
      if (i > 52) {
        weekno = i - 52;
        year = new Date(defaultDate).getFullYear() + 1;
      }
      const startEnd = getAllDatesOfWeek(new Date(defaultDate).getFullYear(), i)
      week.push({ week: weekno, date: startEnd, year: year })
    }
    setmonthWeek(week)
    if (currentView == "week") {
      dispatch(getnewCalendar({ type: "week", date: week, ...selectBox}));
    }
  }, [defaultDate]);
  function getAllDatesOfWeek(year, weekNumber) {
    var startDate = new Date(year, 0, 1 + (weekNumber - 1) * 7);
    var endDate = new Date(year, 0, 1 + (weekNumber - 1) * 7 + 6);
    startDate.setDate(startDate.getDate() - startDate.getDay() + 1);
    var datesOfWeek = [];
    var currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      var formattedDate = currentDate.getFullYear() + '-' +
        ('0' + (currentDate.getMonth() + 1)).slice(-2) + '-' +
        ('0' + currentDate.getDate()).slice(-2);
      datesOfWeek.push(formattedDate);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return datesOfWeek;
  }

  useEffect(() => {
    dispatch(getmonthPending(selectBox))
    if (currentView == "day" && weekDates.length) {
      dispatch(getnewCalendar({ type: "day", date: weekDates, ...selectBox }));
    } else if (currentView == "week" && weekDates.length) {
      dispatch(getnewCalendar({ type: "week", date: monthWeek, ...selectBox }));
    }
  }, [selectBox])
  const { views } = useMemo(() => ({
    views: {
      month: false,
      week: true,
      day: true,
    },
  }), []);

  const { leftView } = useMemo(() => ({
    leftView: {
      Today: true,
      ViewWorkLoad: true,
    },
  }), []);
  const getDateData = (startDate) => {
    const data = newCalendar.data.filter(item => new Date(item.start).getTime() == startDate.getTime())
    return data
  }
  const renderDays = () => {
    const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return (
      <div className="week-view day_view">
        <div className='week-header rbc-row'>
          <div className='prev_icn_black' onClick={()=> handlePrevWeekClick(selectBox)}><img src="/Images/prev_black.svg" /></div>
          <div className="day-label"><img src="/Images/Icon_clock.svg" /></div>
          {weekDays.map((day, index) => (
            <div key={index} className="day-label">
              <div>
                <h6>{day}</h6>
                <>
                  {new Date(weekDates[index]).getDate()}
                </>
              </div>
            </div>
          ))}
          <div className='next_icn_black' onClick={()=> handleNextWeekClick(selectBox)}><img src="/Images/next_black.svg" /></div>
        </div>
        <div className='day-body day_b'>
          {
            Array.from({ length: 24 }, (_, index) => {
              return (
                <div className='d-flex'>
                  <div className="day_sun">{index}</div>
                  {weekDates.map((date, ind) => {
                    return (
                      <div className="day_sun border position-relative">
                        {getDateData(new Date(date + " " + String(index).padStart(2, '0') + ":00:00")).map((value) => {
                          if (value.data == undefined) {
                            return <></>
                          }
                          const daterange = [value.start, value.end]
                          return (
                            <div className='main-box' style={{ width: value.eventDuration * 144 + "px" }}>
                              {/* <div className={`main-box ${value.eventDuration > 1 ? 'expanded-width' : ''}`}> */}
                              {value.data && Object.entries(value.data).map((value, key) => {
                                if (key != 0) {
                                  return;
                                }
                                return <div className='day_tsak_box'>
                                  <div className='task-list'>
                                    {value[1].task.map((task, key) => {
                                      if (key > 1
                                      ) {
                                        return;
                                      }
                                      return (
                                        <>
                                          <div className='task d-flex mb-1'>
                                            <img src={process.env.REACT_APP_IMG_URL + task.image} className='product_image' />
                                            <div class="tooltip_rbc">
                                              <p className={task.days_status == "before" ? "text-success" : "text-danger"}>{task.node}({task.days})</p>
                                              <span className='tooltiptext'>
                                                Request ID : {task.sample_request_no}
                                              </span>
                                            </div>
                                          </div>
                                        </>
                                      )
                                    })}
                                    {value[1].task.length > 2 && <div className='mt-1'>
                                      <div className='more_user' onClick={() => handleSidebarClick(daterange)}>3 more</div>
                                    </div>}
                                  </div>
                                  <div className='d-flex justify-content-center admin_name mt-2' onClick={() => handleSidebarClick(daterange)}>
                                    <img className='img-box' src={process.env.REACT_APP_IMG_URL + value[1].user_image} />
                                    <h6>{value[1].user_name}</h6>
                                  </div>
                                </div>
                              })}
                              {/* calender-sidebar */}
                              <div className="image-container" onClick={() => handleSidebarClick(daterange)}>
                                {value.data && Object.entries(value.data).map((value, key) => {
                                  if (key > 5 || key == 0) {
                                    return;
                                  }
                                  return (
                                    <img className="image-rbc" src={process.env.REACT_APP_IMG_URL + value[1].user_image} />
                                  )
                                })}
                                {value.data && Object.entries(value.data).length > 6 &&
                                  <div className='overLap_text' onClick={() => handleSidebarClick()}>{Object.entries(value.data).length - 6}+</div>
                                }
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    )
                  })}
                </div>
              )
            })
          }

        </div>
      </div>
    );
  };
  //weekData
  const handlePrevWeekClick = () => {
    // Calculate the start date of the previous week
    const prevWeekStartDate = new Date(defaultDate);
    prevWeekStartDate.setDate(prevWeekStartDate.getDate() - 7);

    // Update the defaultDate state with the start date of the previous week
    setDefaultDate(prevWeekStartDate.toDateString());
  };
  const handleNextWeekClick = () => {
    const nextWeekStartDate = new Date(defaultDate);
    nextWeekStartDate.setDate(nextWeekStartDate.getDate() + 7);
    setDefaultDate(nextWeekStartDate.toDateString());
  };
  const getWeekData = (startDate, endDate) => {

    const data = newCalendar.data.filter(item => {
      return new Date(item.start).getTime() == new Date(startDate).getTime()
    });
    return data;
  }
  useEffect(() => {
    // Call the necessary function with selectBox value
    if (currentView === 'day' && weekDates.length) {
      dispatch(getnewCalendar({ type: 'day', date: weekDates, ...selectBox }));
    } else if (currentView === 'week' && weekDates.length) {
      dispatch(getnewCalendar({ type: 'week', date: monthWeek, ...selectBox }));
    }
  }, [selectBox, currentView, weekDates, monthWeek]);
  const renderWeek = () => {
    const weekDays = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

    return (
      <div className="week-view">
        <div className='week-header rbc-row'>
          <div className='prev_icn_black' onClick={()=>handlePrevWeekClick(selectBox)}><img src="/Images/prev_black.svg" /></div>
          <div className="day-label"><img src="/Images/Icon_clock.svg" /></div>
          {monthWeek.map((day, index) => (
            <div key={index} className="day-label">
              <div>
                <h6>Week</h6>
                <>
                  {day.week}

                </>
              </div>
            </div>
          ))}
          <div className='next_icn_black' onClick={()=>handleNextWeekClick(selectBox)}><img src="/Images/next_black.svg" /></div>
        </div>
        <div className='day-body'>
          {
            weekDays.map((day, index) => {
              return (
                <div className='d-flex'>
                  <div key={index} className={`day ${day === 'SUN' ? 'sunday-box' : ''}`}>
                    {day}
                  </div>
                  {monthWeek.map((date, ind) => {
                    return (
                      <div className="day border position-relative">
                        {/* { date.date[index]} */}

                        {getWeekData(new Date(date.date[index] + " 00:00:00"),
                          new Date(date.date[index] + " 23:59:59")).map((value) => {
                            if (value.data == undefined) {
                              return <></>
                            }
                            const daterange = [value.start, value.end]
                            return (
                              <div className='main-box main-box-week' style={{ width: value.eventDuration * 154 + "px" }}>

                                {/* <div className={`main-box ${value.eventDuration > 1 ? 'expanded-width' : ''}`}> */}
                                {value.data && Object.entries(value.data).map((value, key) => {
                                  if (key != 0) {
                                    return;
                                  }

                                  return <div className='task-box'>
                                    <div className='task-list'>
                                      {value[1].task != undefined && value[1].task.map((task, key) => {
                                        if (key > 1) {
                                          return;
                                        }
                                        return (
                                          <>
                                            <div className='task d-flex'>
                                              <img src={process.env.REACT_APP_IMG_URL + task.image} className='product_image' />
                                              <div class="tooltip_rbc">
                                                <p className={task.days_status == "before" ? "text-success" : "text-danger"}>{task.node}({task.days})</p>
                                                <span className='tooltiptext'>
                                                  Request ID : {task.sample_request_no}
                                                </span>
                                              </div>
                                            </div>
                                          </>
                                        )
                                      })}
                                      {value[1].task.length > 2 && <div className='mt-1'><div className='more_user' onClick={() => handleSidebarClick(daterange)}>3 more</div></div>}
                                    </div>
                                    <div className='d-flex justify-content-center admin_name mt-2'><img className='img-box' src={process.env.REACT_APP_IMG_URL + value[1].user_image} />
                                      <h6>{value[1].user_name}</h6>
                                    </div>

                                  </div>
                                })}
                                <div className="image-container" onClick={() => handleSidebarClick(daterange)}>
                                  {value.data && Object.entries(value.data).map((value, key) => {
                                    if (key > 5 || key == 0) {
                                      return;
                                    }

                                    return (
                                      <img className="image-rbc" src={process.env.REACT_APP_IMG_URL + value[1].user_image} />
                                    )
                                  })}

                                  {value.data && Object.entries(value.data).length > 6 &&
                                    <div className='overLap_text' onClick={() => handleSidebarClick}>{Object.entries(value.data).length - 6}+</div>
                                  }
                                </div>
                              </div>
                            )
                          })}
                      </div>
                    )
                  })}
                </div>
              )
            })
          }

        </div>
      </div>

    );
  };




  const handlePrevMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
  };

  const handleNextMonth = () => {
    setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
  };

  const handleViewChange = (view) => {
    setCurrentView(view);

    if (view == "day") {
      dispatch(getnewCalendar({ type: "day", date: weekDates,...selectBox}));
    } else {
      dispatch(getnewCalendar({ type: "week", date: monthWeek,...selectBox }));
    }
  };

  const handleTodayClick = () => {
    setDefaultDate(new Date().toDateString()); // Set current date to today
  };

  const ref = useRef(null);

  const scroll = () => {
    ref.scrollX += 20;
  };


  return (
    <div className="custom-big-calendar">
      <div className='custom-calender'>
        <div className='month_black_box'>
          <CalendarHeader
            // currentDate={new Date()}
            currentDate={defaultDate}
            setDefaultDate={setDefaultDate}
            selectBox={selectBox}

          />

        </div>
        <div className='rbc_event_body'>
          <div className="calendar-header">
            <div className="left-view">
              {leftView.Today && (
                <button className="left-view-btn" onClick={() => handleTodayClick()}>
                  Today
                </button>
              )}
              {leftView.ViewWorkLoad && (
                <button className="left-view-btn-work">
                  <Link to="/workload">  View Work Load</Link>

                </button>
              )}
            </div>
            {leftView.prev && <button onClick={() => handlePrevMonth()}>&lt;</button>}
            <h2>{new Date(defaultDate).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</h2>
            {leftView.next && <button onClick={() => handleNextMonth()}>&gt;</button>}
            <div className="view-controls">

              {views.week && (
                <button
                  className={currentView === 'week' ? 'active' : ''}
                  onClick={() => handleViewChange('week')}
                >
                  Week
                </button>
              )}
              {views.day && (
                <button
                  className={currentView === 'day' ? 'active' : ''}
                  onClick={() => handleViewChange('day')}
                >
                  Day
                </button>
              )}
            </div>
          </div>
          <div className="calendar-grid">
            {currentView === 'day' && renderDays()}
            {currentView === 'week' && renderWeek()}
          </div>
        </div>
        {isSidebarOpen && (
          <div className="sidebar">

            <CalendarSidebar onCloseSidebar={handleCloseSidebar} dateRange={dateRangeDetail} />
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomBigCalendar;
