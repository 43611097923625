import React, { useEffect, useState } from 'react';
import { Select, DatePicker } from 'antd';
import { useDropzone } from 'react-dropzone'
import AudioRemark from './AudioRemark';
import { useDispatch, useSelector } from 'react-redux';
import { getremarkData, getsampleNodeData } from '../../../store/user/actions';
import { Link } from 'react-router-dom';


const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

const RemarksModal = (props) => {
  const initialValues = {
    sample_category_node_transaction_id: "",
    voice_note:'',
    files:''
  };
  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState('');
  const [audio, setAudio] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [patternMaking, setPatternMaking] = useState([])
  const [formValues, setFormValue] = useState(initialValues);
  const [audioBox, setAudioBox] = useState(true);
  const [isDropzoneVisible, setDropzoneVisibility] = useState(true);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const sampleNodeData = useSelector(state => state.sampleNodeData)
  const remarkData = useSelector (state => state.remarkData)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getsampleNodeData({sampleRequestId:props.requestId}))
  }, [])

  useEffect(() => {
    const patternData = sampleNodeData.data.map((item) => ({
      value: parseInt(item.id),
      label:item.name,
    }));
    setPatternMaking(patternData)
  },[sampleNodeData])

  const audioValue = (selectValue) => {
    setAudioBox(selectValue)
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': []
    },
    multiple: true, 
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
      if (acceptedFiles.length > 0) {
        // Assuming you only want to display the first uploaded image
        setUploadedImage(acceptedFiles[0]);
        setDropzoneVisibility(false); // Hide the dropzone
      }
    }
  });
  const showDropzone = () => {
    setUploadedImage(null); // Clear the uploaded image
    setDropzoneVisibility(true); // Show the dropzone again
  };
  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />
      </div>
    </div>
  ));


 
  const onChange = (name, value) => {
    let data = { ...initialValues};
    data[name] = value;
    setFormValue();
  };
  const handleChange = (name, value) => {
    let data = { ...formValues };
    data[name] = value;
    setFormValue(data);
  };

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    setFiles([...selectedFiles]);
  };

  const handleSubmit = (e, name, value) => {
    setError(null);
    setLoading(true);
    let check = validate(formValues);
    let data = { ...initialValues };
    data[name] = value;
    e.preventDefault();
    setFormErrors(check.errors);
    if (check.isValid) {
      setLoading(false);
      const formData = new FormData();
      files.forEach((file, index) => {
        formData.append(`file[${index}]`, file);
      });
      if(audioBox[0]){
        formData.append("voice_note", audioBox[0].mediaBlob)
      }
      formData.append("remark", formValues.remark)
      formData.append("react", "reactData")
      formData.append("sample_category_node_transaction_id", formValues.sample_category_node_transaction_id)
      dispatch(getremarkData(formData))
      if(!remarkData.isLoading){
        props.mClose(false)
      }
      
    }
  }
   // validation-start
   useEffect(() => {
    if (Object.keys(formErrors).length > 0) {
    }
  }, [formErrors]);

  const validate = (values) => {
    let isValid = true;
    const errors = {};
    if (!values.sample_category_node_transaction_id) {
      isValid = false;
      errors.sample_category_node_transaction_id = "select node is required";
    }
    if (!values.remark) {
      isValid = false;
      errors.remark = "remark is required ";
    }
    // if (!audioBox.length) {
    //   isValid = false;
    //   errors.voice_note = "voice is required";
    // }
    if (!files.length) {
      isValid = false;
      errors.files = "files is required";
    }
    return {
      isValid,
      errors,
    };
  };
  return (
    <div className='remark-select' >
      <form onSubmit={handleSubmit}>
        <div>
      <h6>Select Node</h6>
      <Select
        defaultValue="Select Node"
        onChange={(e) => handleChange("sample_category_node_transaction_id", e)}
        options={patternMaking}
      />
        <span className='error-msg'>{formErrors.sample_category_node_transaction_id}</span>
      </div>
      <h6>Remarks</h6>
      <div >
        <textarea  
         onChange={(e) => handleChange("remark", e.target.value)}
        className='card_box'
        />
         <span className='error-msg'>{formErrors.remark}</span>
      </div>
      <div>
      <div {...getRootProps({ className: 'upload-file' })}>
        <input {...getInputProps()} multiple onChange={handleFileChange}/>
        {
          files.length==0 ? 
        
        <div>
        <img src="/Images/cloud.svg" />
        <h5>Upload Image</h5>
        <p>Drag and Drop file here Or <b>Choose file</b></p>
        <small>Support formats: JPG, PNG | Minimum size: 5 MB</small>
        </div>
       :<></>}
        <aside style={thumbsContainer}>
          {thumbs}
        </aside>
      </div>
      <span className='error-msg'>{formErrors.files}</span>
      </div>
      <div>
      <AudioRemark 
      audioValue={audioValue}
      />
      {/* <span className='error-msg'>{formErrors.voice_note}</span> */}
      </div>
    <div className="remark-buttons">
        <button className='gray-but' type='button'><Link to={"/history/"+props.requestId}>history</Link></button>
        <button className='dark-but' 
        type="submit"
        > 
      
        Submit
        </button>
      </div>
     </form> 
     {message && <p>{message}</p>} 
    </div>
  )
} 

export default RemarksModal
