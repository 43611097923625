

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getrequestStatusHold } from '../../../store/user/actions';

const CustomSwitch = ({ label,status,id,refreshNodeData }) => {
    const [isChecked, setChecked] = useState(status);
    // const requestStatusHold = useSelector(state => state.requestStatusHold)
    const requestStatusHold = useSelector(state => state.requestStatusHold)

    const dispatch = useDispatch()

    const resetCheckbox = () => {
      if (isChecked==true) {
        dispatch(getrequestStatusHold({id:id,status:"hold"}))
      }else {
        dispatch(getrequestStatusHold({id:id,status:"unhold"}))
      }
       setChecked(!isChecked)
    };
    useEffect( () => {
      if (requestStatusHold.isSuccess){
         refreshNodeData()
      }
      },[requestStatusHold])
  return (
    <div>
      <div className="custom-switch">
      {/* <p>{label}{" "} </p> */}
     
      <div className="toggle-switch">
        <input type="checkbox" className="checkbox"
          name={label} id={label}
          checked={isChecked}
          onChange={resetCheckbox}
      
          // {status?"":"disable"}
          // disabled={!isChecked}
          />
        <label className="label" htmlFor={label}>
          <span className="innerHold" />
          <span className="switch" />
        </label>
      </div>
    </div>
    </div>
  )
}

export default CustomSwitch
